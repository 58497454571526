import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Circles } from 'react-loader-spinner'
import NewCards from '../../screens/NewsFeedScreen/components/NewCards'
import FilterListIcon from '@mui/icons-material/FilterList'

export default function CompanyNewsV2(props) {
    const { setNewsFilters } = props
    return (
        <>
            <div
                className="companyNews__heading d-flex alig-items-center justify-content-between mb-2"
                // style={{ width: props.rowCheck ? '65%' : '100%' }}
            >
                <div
                    className="headingGeneric company__overviewHeading"
                    style={{ fontWeight: '800', fontSize: '20px' }}
                >
                    News & Key Updates
                </div>
                <div
                    style={{
                        cursor: 'pointer',
                        borderRadius: '4px',
                        background: 'white',
                        padding: '5px',
                        border: '1px solid lightgray',
                    }}
                    onClick={() => {
                        setNewsFilters(true)
                    }}
                >
                    <span>
                        <FilterListIcon />
                    </span>
                    <span className="ms-2">Filters</span>
                </div>
            </div>
            {props.CompanyNews.data && (
                <InfiniteScroll
                    pageStart={0}
                    loadMore={props.handleLoadMore}
                    hasMore={props.CompanyNews.next !== null}
                    loader={
                        <div style={{ marginTop: '5%', marginLeft: '20px' }}>
                            <Circles key={0} type="Grid" color="#adadad" height={80} width={80} />
                        </div>
                    }
                >
                    <div className="companyNews__storyMap">
                        <div
                            style={{
                                minWidth: '450px',
                                display: 'grid',
                                gridTemplateColumns: props.rowCheck ? '1fr 1fr' : '1fr 1fr 1fr',
                                gridColumn: '20px',
                                columnGap: '20px',
                                marginTop: '-20PX',
                            }}
                        >
                            {props.CompanyNews.data
                                .sort((a, b) => (a.date.split('T')[0] > b.date.split('T')[0] ? -1 : 1))
                                .map((story, index) => {
                                    let hasMargin = false
                                    if (index % 3 !== 2 && props.windowWidth > 900) {
                                        hasMargin = true
                                    }
                                    return <NewCards val={story} index={index} isTopicPage={true} />
                                })}
                        </div>
                    </div>
                </InfiniteScroll>
            )}
        </>
    )
}
