import React from 'react'
import FilterListIcon from '@mui/icons-material/FilterList'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

export default function NewsHeaderSection(props) {
    const {
        filterLength,
        newsData,
        setNewsFilters,
        currentDate,
        sortBy,
        setSortBy,
        isAllSelected,
        setAllSelected,
        isQuickSearch,
        isAdvanceSearch,
        isMyRequest,
        isCreationMyRequest,
        isMyRequestPage,
        isAssistant,
        handleAllSelected,
    } = props
    return (
        <>
            <div
                className={`d-flex align-items-center justify-content-between ${isAdvanceSearch ? 'm-auto' : ''}`}
                style={{ width: isAdvanceSearch ? '97%' : '' }}
            >
                {isQuickSearch || isMyRequestPage || isAssistant ? (
                    <div
                        onClick={() => {
                            setNewsFilters(true)
                        }}
                        style={{ cursor: 'pointer' }}
                        title="Click to add filters"
                        className="d-flex align-items-center"
                    >
                        <span className="font-h"> Filters {filterLength !== 0 && '(' + filterLength + ')'}</span>
                        <span className="d-none d-sm-block">
                            <FilterListIcon className="font-h d-none d-sm-block" />
                        </span>
                    </div>
                ) : (
                    <div />
                )}

                <div className="font-h d-none d-sm-block">
                    Showing 1 - {newsData.data.length} of {newsData.total} results
                    {currentDate && (
                        <>
                            {' '}
                            for last{' '}
                            {isQuickSearch || isMyRequestPage
                                ? currentDate === 2
                                    ? 1 + ' day'
                                    : currentDate === 3
                                    ? 2 + ' days'
                                    : currentDate + ' days'
                                : currentDate + ' days'}
                        </>
                    )}
                </div>
                <div>
                    {(isQuickSearch || isMyRequestPage || isAssistant) && (
                        <select
                            style={{ outline: 'none', borderRadius: '4px', background: 0 }}
                            value={sortBy}
                            onChange={e => {
                                setSortBy(e.target.value)
                            }}
                            disabled={
                                (isQuickSearch || isMyRequestPage || isAssistant) && newsData.data[0].score
                                    ? false
                                    : true
                            }
                        >
                            <option value={null} style={{ display: 'none' }}>
                                Sort by
                            </option>
                            <option value="date" style={{ borderRadius: '0px' }}>
                                Date
                            </option>
                            <option value="relevance">Relevance</option>
                        </select>
                    )}
                </div>
            </div>
            <div
                style={{
                    width: isAdvanceSearch ? '98%' : '',
                    margin: isAdvanceSearch ? 'auto' : '',
                    marginTop: isAdvanceSearch ? '15px' : '',
                }}
            >
                <div
                    style={{
                        background: '#00193C',
                        color: 'white',
                        padding: '10px',
                        borderRadius: '4px 4px 0px 0px',
                        marginTop: '10px',
                    }}
                    className="d-flex align-items-center justify-content-between"
                >
                    <div style={{ width: newsData.data[0].score ? '52%' : '57%' }}>
                        {!isMyRequestPage && !isMyRequest && !isCreationMyRequest && (
                            <span
                                style={{ marginLeft: '1%', cursor: 'pointer' }}
                                onClick={() => {
                                    !isAssistant && handleAllSelected()

                                    setAllSelected(!isAllSelected)
                                }}
                            >
                                {!isAllSelected ? (
                                    <CheckBoxOutlineBlankIcon style={{ fontSize: 'larger' }} />
                                ) : (
                                    <CheckBoxIcon style={{ fontSize: 'larger' }} />
                                )}
                            </span>
                        )}
                        <span
                            style={{
                                marginLeft:
                                    isQuickSearch && newsData.data[0].score
                                        ? '3%'
                                        : isQuickSearch && !newsData.data[0].score
                                        ? '6%'
                                        : isMyRequestPage && newsData.data[0].score
                                        ? '6%'
                                        : isMyRequestPage && !newsData.data[0].score
                                        ? '9%'
                                        : '6%',
                            }}
                            className={isAssistant ? 'font-h ml-30' : ''}
                        >
                            Title
                        </span>
                    </div>
                    {(isQuickSearch || isMyRequestPage || isAssistant) && newsData.data[0].score && (
                        <div className="d-none d-sm-block font-h" style={{ width: '10%' }}>
                            Relevance
                        </div>
                    )}
                    <div
                        className="d-none d-sm-block font-h"
                        style={{
                            width: '15%',
                            marginLeft: newsData.data[0].score ? '2%' : '',
                        }}
                    >
                        Source
                    </div>
                    <div className="d-none d-sm-block font-h" style={{ width: '15%', marginRight: '1%' }}>
                        Date
                    </div>
                    <div />
                </div>
            </div>
        </>
    )
}
