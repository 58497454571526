import React, { useEffect, useRef, useState } from 'react'
import { getFullFormattedDate } from '../../../utils/dateHelpers'
import ImageComponent from '../../../components/Image/Index'
import { FaEnvelope, FaLinkedin, FaTwitter } from 'react-icons/fa'
import twitterX from '../../../assets/icons/twitterx-blue.svg'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import axios from 'axios'
import { AiFillCloseCircle } from 'react-icons/ai'
import { MdContentCopy } from 'react-icons/md'
import duplicateImg from '../../../assets/icons/connectingvector.svg'

export default function NewCards(props) {
    const { val, index, isTopicPage, emailNewsLoader, setEmailNewsLoader, isFindAndShare } = props

    const newimage = require('./../../../assets/images/card2.jpg')
    const newimage1 = require('./../../../assets/images/n1.jpeg')
    const newimage2 = require('./../../../assets/images/n2.jpg')
    const newimage3 = require('./../../../assets/images/n3.jpg')
    const newimage4 = require('./../../../assets/images/n4.png')
    const newimage5 = require('./../../../assets/images/n5.jpg')

    const [readMore, setReadMore] = useState(false)
    const [content, setContent] = useState('')
    const [header, setHeader] = useState('')
    const [result, setResult] = useState('')
    const [socialReadMore, setSocialReadMore] = useState(false)
    const [generate, setGenerate] = useState(false)
    const [emailContent, setEmailContent] = useState([])
    const [showContentOf, setShowContentOf] = useState('')
    const [isModalLoader, setIsModalLoader] = useState(false)
    const [otherSource, setOtherSource] = useState([])
    const textareaRef = useRef(null)
    let interval

    const Generate = () => {
        result.toString()
        const removebr = result.replace(/(<([^>]+)>)/gi, '')
        if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(removebr)
            var tooltip = document.getElementById('thoughtTooltip1')
            tooltip.innerHTML = 'Copy to clipboard'
            setTimeout(() => {
                tooltip.innerHTML = ''
            }, 1000)
        }
    }

    function GenerateText() {
        setTimeout(() => {
            const search = document.getElementById('generating')
            if (search) {
                const searchPlaceholder = 'Generating...'
                let i = 0
                let placeholder = ''
                let speed = 300
                typing()
                function typing() {
                    clearInterval(interval)
                    interval = setInterval(() => {
                        if (search) {
                            placeholder += searchPlaceholder[i]
                            i++
                            search.value = placeholder
                            search.scrollTop = search.scrollHeight
                            if (i > searchPlaceholder.length - 1) {
                                clearInterval(interval)
                            }
                        }
                    }, 30)
                }
            }
        }, 500)
    }

    const GenerateContent = (val, payLoad) => {
        if (!props.isRecommendationMailNews) {
            setIsModalLoader(true)
            GenerateText()
            setGenerate(!generate)
            axios
                .post(`/core/generate-content/`, payLoad)
                .then(response => {
                    if (response.data === 'You have exceeded the limit to generate content') {
                        setIsModalLoader(false)
                        setGenerate(generate)
                        toast('You have exceeded the limit to generate content')
                    } else {
                        setGenerate(!generate)
                        setIsModalLoader(false)
                        setResult(response.data)
                        const search = document.getElementById('otherArea')
                        if (search) {
                            const searchPlaceholder = response.data
                            let i = 0
                            let placeholder = ''
                            clearInterval(interval)
                            interval = setInterval(() => {
                                if (search && i < searchPlaceholder.length) {
                                    placeholder += searchPlaceholder[i]
                                    i++
                                    search.value = placeholder
                                    search.scrollTop = search.scrollHeight
                                } else {
                                    clearInterval(interval)
                                }
                            }, 30)
                        }
                        setIsModalLoader(false)
                    }
                })
                .catch(err => {
                    console.log(err)
                    setIsModalLoader(false)
                })
        } else {
            setEmailNewsLoader(true)
            setTimeout(() => {
                document.getElementById('emailNewsArea').value = ''
            }, 100)
            const search = document.getElementById('emailNewsArea')
            if (search) {
                const searchPlaceholder = 'Generating...'
                let i = 0
                let placeholder = ''
                typing()
                function typing() {
                    clearInterval(interval)
                    interval = setInterval(() => {
                        if (search) {
                            placeholder += searchPlaceholder[i]
                            i++
                            search.value = placeholder
                            search.scrollTop = search.scrollHeight
                            if (i > searchPlaceholder.length - 1) {
                                clearInterval(interval)
                            }
                        }
                    }, 400)
                }
            }
            axios
                .post(`/core/generate-content/`, payLoad)
                .then(response => {
                    if (response.data === 'You have exceeded the limit to generate content') {
                        setEmailNewsLoader(false)
                        toast('You have exceeded the limit to generate content')
                    } else {
                        setResult(response.data)
                        const search = document.getElementById('emailNewsArea')
                        if (search) {
                            const searchPlaceholder = response.data
                            let i = 0
                            let placeholder = ''
                            clearInterval(interval)
                            interval = setInterval(() => {
                                if (search && i < searchPlaceholder.length) {
                                    placeholder += searchPlaceholder[i]
                                    i++
                                    search.value = placeholder
                                    search.scrollTop = search.scrollHeight
                                } else {
                                    clearInterval(interval)
                                }
                            }, 30)
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                    setEmailNewsLoader(false)
                })
        }
    }

    const AlreadyGenerated = (val, type) => {
        if (!props.isRecommendationMailNews) {
            setGenerate(!generate)

            setTimeout(() => {
                const search = document.getElementById('otherArea')
                if (search) {
                    const searchPlaceholder =
                        type === 'linkedin' ? val.linkedin : type === 'twitter' ? val.twitter : val.email
                    let i = 0
                    let placeholder = ''
                    typing()
                    function typing() {
                        clearInterval(interval)
                        interval = setInterval(() => {
                            if (search) {
                                placeholder += searchPlaceholder[i]
                                i++
                                search.value = placeholder
                                search.scrollTop = search.scrollHeight
                                if (i > searchPlaceholder.length - 1) {
                                    clearInterval(interval)
                                }
                            }
                        }, 10)
                    }
                }
            }, result)
        } else {
            setEmailNewsLoader(true)
            setTimeout(() => {
                document.getElementById('emailNewsArea').value = ''
            }, 100)
            setTimeout(() => {
                const search = document.getElementById('emailNewsArea')
                if (search) {
                    const searchPlaceholder =
                        type === 'linkedin' ? val.linkedin : type === 'twitter' ? val.twitter : val.email
                    let i = 0
                    let placeholder = ''
                    typing()
                    function typing() {
                        clearInterval(interval)
                        interval = setInterval(() => {
                            if (search) {
                                placeholder += searchPlaceholder[i]
                                i++
                                search.value = placeholder
                                search.scrollTop = search.scrollHeight
                                if (i > searchPlaceholder.length - 1) {
                                    clearInterval(interval)
                                }
                            }
                        }, 10)
                    }
                }
            }, 100)
        }
    }

    const mediaMapping = {
        linkedin: {
            media: 'LinkedIn',
            condition: val => val.linkedin === null || val.linkedin === '' || val.linkedin === undefined,
        },
        twitter: {
            media: 'Twitter',
            condition: val => val.twitter === null || val.twitter === '' || val.twitter === undefined,
        },
        email: { media: 'Email', condition: val => val.email === null || val.email === '' || val.email === undefined },
    }

    const newsGenerate = (val, act, type) => {
        console.log(val)
        const mapping = mediaMapping[type]

        if (mapping && act === (type === 'email' ? 'Client Outreach' : 'Social Media') && mapping.condition(val)) {
            console.log(type)

            const payLoad = {
                id: val.id,
                action: act,
                media: mapping.media,
                snippet: val.snippet.length === 0 ? val.news_url : val.snippet,
                sectors: val.sectors,
            }
            GenerateContent(val, payLoad)
        }
    }

    const emailIconFunction = (val, type) => {
        setShowContentOf(type)
        setEmailContent(val)
        setResult(val.email)
        if (val.email === null || val.email === undefined || val.email === '') {
            newsGenerate(val, 'Client Outreach', 'email')
        } else {
            AlreadyGenerated(val, 'email')
        }
    }

    const linkedinIconFunction = (val, type) => {
        setShowContentOf(type)
        setEmailContent(val)
        setResult(val.linkedin)
        if (val.linkedin === null || val.linkedin === undefined || val.linkedin === '') {
            newsGenerate(val, 'Social Media', 'linkedin')
        } else {
            AlreadyGenerated(val, 'linkedin')
        }
    }

    const twitterIconFunction = (val, type) => {
        setShowContentOf(type)
        setEmailContent(val)
        setResult(val.twitter)
        if (val.twitter === null || val.twitter === undefined || val.twitter === '') {
            newsGenerate(val, 'Social Media', 'twitter')
        } else {
            AlreadyGenerated(val, 'twitter')
        }
    }

    const snipOpen = val => {
        if (props.isRecommendationSocial || props.isRecommendationMailNews || isTopicPage) setReadMore(!readMore)
        if (props.isRecommendationClient && props.isRecommendationMailNews && !isTopicPage)
            setSocialReadMore(!socialReadMore)
        if (val.other_sources) {
            setOtherSource(val.other_sources)
        } else {
            setOtherSource([])
        }
        setContent(val.snippet)
        setHeader(val.title)
        setResult(val.snippet)
    }

    const [isImageError, setIsImageError] = useState(false)
    const handleImageError = () => {
        setIsImageError(true)
    }
    return (
        <div className={emailNewsLoader ? 'd-flex justify-content-between' : ''}>
            {/* new card */}
            <div style={{ width: emailNewsLoader ? '50%' : '' }}>
                <div
                    className={!props.isRecommendationSocial ? 'card-group' : ''}
                    style={{ marginTop: isTopicPage ? '20px' : '', width: emailNewsLoader ? '100%' : '' }}
                >
                    <div
                        className={!props.isRecommendationSocial ? 'card' : ''}
                        style={{
                            height: isFindAndShare ? '' : '450px',
                            border: isFindAndShare ? 'none' : '1px solid rgba(0,0,0,.125)',
                        }}
                    >
                        {!isFindAndShare && (
                            <img
                                src={!val.image ? newimage : val.image}
                                className="card-img-top imgg"
                                alt="image"
                                style={{ maxHeight: '180px', minHeight: '180px', height: '180px', cursor: 'pointer' }}
                                onClick={() => {
                                    !props.isRecommendationMailNews && snipOpen(val)
                                }}
                                onError={handleImageError}
                            />
                        )}

                        {!isFindAndShare && (
                            <div
                                className={!props.isRecommendationSocial ? 'card-body' : ''}
                                style={{
                                    overflow: 'hidden',
                                    maxHeight: '270px',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'normal',
                                    padding: props.isRecommendationSocial ? '0rem 1rem' : '',
                                    marginTop: props.isRecommendationSocial ? '10px' : '',
                                }}
                            >
                                <div className="d-flex justify-content-between" style={{ marginBottom: '-8px' }}>
                                    <h5>{getFullFormattedDate(val.date)}</h5>
                                    <a
                                        href={val.news_url ? val.news_url : val.source ? val.source : ''}
                                        style={{ textDecoration: 'none' }}
                                        target="_blank"
                                    >
                                        <h5 title="source" className="d-flex align-items-center">
                                            {(val.source_name || val.source) && val.source_subscription && (
                                                <img
                                                    src="https://kaitongo-static.s3.amazonaws.com/insights/static/paywall.svg"
                                                    style={{ width: '12px', height: '16px', marginRight: '5px' }}
                                                />
                                            )}

                                            {val.source_name && val.source_name !== ''
                                                ? val.source_name
                                                : val.source
                                                      .match(
                                                          /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/gim
                                                      )[0]
                                                      .replace(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?/gim, '')}
                                        </h5>
                                    </a>
                                </div>
                                <a href={val.news_url} target="_blank" title={val.title}>
                                    <h4
                                        className="card-title"
                                        style={{
                                            cursor: 'pointer',
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: 'vertical',
                                            fontSize: '17px',
                                            marginTop: props.isRecommendationSocial ? '10px' : '',
                                        }}
                                    >
                                        {val.title.replace(/\\/g, ' ')}
                                    </h4>
                                </a>
                                <p
                                    className="cut-offtext"
                                    style={{
                                        cursor: 'pointer',
                                        overflow: 'hidden',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 5,
                                        WebkitBoxOrient: 'vertical',
                                    }}
                                    onClick={() => {
                                        !props.isRecommendationMailNews && snipOpen(val)
                                    }}
                                >
                                    {val.snippet}
                                </p>
                            </div>
                        )}
                        <div
                            className="card-footer"
                            style={{
                                marginTop: 'auto',
                                width: '100%',
                                borderTop: '0px',
                                background: 'white',
                                padding: isFindAndShare ? '' : '0.8rem 1rem',
                            }}
                        >
                            <div
                                className="CompanyNewsCard__container d-flex align-items-center"
                                style={{
                                    marginTop: props.isRecommendationMailNews ? '15px' : '',
                                    marginRight: '40px',
                                    width: '100% ',
                                    boxShadow: 'none',
                                    borderLeft: isFindAndShare ? '' : 'solid 1px #021a36',
                                }}
                            >
                                <span
                                    onClick={() => {
                                        linkedinIconFunction(val, 'LINKEDIN')
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <FaLinkedin
                                        style={{
                                            marginTop: '-5px',
                                            color: 'rgb(25, 118, 210)',
                                            fontSize: '20px',
                                            marginLeft: '10px',
                                        }}
                                    />
                                </span>
                                <span
                                    onClick={() => {
                                        twitterIconFunction(val, 'TWITTER')
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <img
                                        src={twitterX}
                                        style={{
                                            marginTop: '-5px',
                                            marginLeft: '10px',
                                            width: '20px',
                                        }}
                                        alt="twitter"
                                    />
                                </span>

                                <span
                                    onClick={() => {
                                        emailIconFunction(val, 'EMAIL')
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <FaEnvelope
                                        style={{
                                            marginTop: '-5px',
                                            color: 'rgb(25, 118, 210)',
                                            fontSize: '20px',
                                            marginLeft: '10px',
                                        }}
                                    />
                                </span>

                                {val.duplicate_news && val.duplicate_news.length > 0 && (
                                    <span>
                                        <img
                                            src={duplicateImg}
                                            style={{ width: '30px', height: '30px', marginLeft: '10px' }}
                                            alt="similar_news"
                                        />
                                    </span>
                                )}

                                {val.subcategories && val.subcategories.length > 0 && (
                                    <span style={{ marginLeft: 'auto' }} title="Business Events">
                                        {val.subcategories.map(v => (
                                            <span
                                                className="sectors_tag_value"
                                                style={{
                                                    margin: '2px 0px',
                                                    fontSize: '14px',
                                                    background: 'rgb(25, 118, 210)',
                                                }}
                                            >
                                                {v.name}
                                            </span>
                                        ))}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={generate}
                    toggle={() => {
                        setGenerate(!generate)
                    }}
                    size="lg"
                >
                    <div className="modal_header_black">
                        <span style={{ color: 'white' }}>
                            {showContentOf === 'EMAIL' ? 'AI Recommended Email' : 'AI Recommended Post'}
                        </span>
                        <span
                            onClick={() => {
                                setGenerate(!generate)
                                // getRecommendationContent()
                            }}
                            className="modal_close_btn"
                        >
                            <AiFillCloseCircle />
                        </span>
                    </div>

                    {isModalLoader ? (
                        <ModalBody>
                            <input
                                style={{ width: '97%', height: '30px', outline: 'none', border: 'none' }}
                                id="generating"
                                value={''}
                            />
                        </ModalBody>
                    ) : (
                        <>
                            <ModalBody>
                                <span className="tooltiptext" id="thoughtTooltip1" />
                                <MdContentCopy onClick={Generate} style={{ marginLeft: '97%', cursor: 'pointer' }} />
                                <br />
                                {/* <Typed style={{ whiteSpace: 'pre-wrap' }} strings={[result]} typeSpeed={10} /> */}
                                <textarea
                                    style={{ width: '97%', height: '200px' }}
                                    id="otherArea"
                                    ref={textareaRef}
                                    // value={''}
                                    placeholder="AI Recommendation Content"
                                    readOnly
                                />
                            </ModalBody>
                            {showContentOf === 'EMAIL' && (
                                <ModalFooter>
                                    <a
                                        href={`mailto:?to=&body=${
                                            emailContent.email
                                                ? encodeURIComponent(
                                                      (emailContent.email &&
                                                          emailContent.email.substr(
                                                              emailContent.email.indexOf('\n') + 1
                                                          )) ||
                                                          ''
                                                  ).replace(/\n/g, '%0D')
                                                : encodeURIComponent(
                                                      (result && result.substr(result.indexOf('\n') + 1)) || ''
                                                  ).replace(/\n/g, '%0D')
                                        }%0D${
                                            emailContent.news_url
                                                ? emailContent.news_url
                                                : emailContent.source
                                                ? emailContent.source
                                                : ''
                                        }%0D&subject=${
                                            emailContent.email && emailContent.email.toLowerCase().includes('subject')
                                                ? encodeURIComponent(
                                                      emailContent.email.substr(0, emailContent.email.indexOf('\n')) ||
                                                          ''
                                                  ).replace('Subject:', '')
                                                : emailContent.title
                                        }`}
                                    >
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => {
                                                setGenerate(!generate)
                                            }}
                                        >
                                            Send
                                        </button>
                                    </a>
                                </ModalFooter>
                            )}
                            {showContentOf === 'LINKEDIN' && (
                                <ModalFooter>
                                    <a
                                        href={`http://www.linkedin.com/share?mini=true&title=${encodeURIComponent(
                                            emailContent.title
                                        )}%0D&text=${encodeURIComponent(
                                            emailContent.linkedin ? emailContent.linkedin : result
                                        )}%0D${encodeURIComponent(
                                            emailContent.news_url
                                                ? emailContent.news_url
                                                : emailContent.source
                                                ? emailContent.source
                                                : ''
                                        )}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => {
                                                setGenerate(!generate)
                                            }}
                                        >
                                            Post
                                        </button>
                                    </a>
                                </ModalFooter>
                            )}

                            {showContentOf === 'TWITTER' && (
                                <ModalFooter>
                                    <a
                                        href={`http://twitter.com/share?&title=${encodeURIComponent(
                                            emailContent.title
                                        )}%0D&url=${encodeURIComponent(
                                            emailContent.news_url
                                                ? emailContent.news_url
                                                : emailContent.source
                                                ? emailContent.source
                                                : ''
                                        )}%0D&text=${encodeURIComponent(
                                            emailContent.twitter ? emailContent.twitter : result
                                        )}%0D&hashtags=${emailContent.twitter ? '' : ''}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => {
                                                setGenerate(!generate)
                                            }}
                                        >
                                            Post
                                        </button>
                                    </a>
                                </ModalFooter>
                            )}
                        </>
                    )}
                </Modal>
                <Modal
                    isOpen={readMore}
                    toggle={() => {
                        setReadMore(!readMore)
                    }}
                    size="lg"
                >
                    <div className="modal_header_black">
                        <span style={{ color: 'white' }}>{header}</span>
                        <span
                            onClick={() => {
                                setReadMore(!readMore)
                            }}
                            className="modal_close_btn"
                        >
                            <AiFillCloseCircle />
                        </span>
                    </div>

                    <ModalBody>
                        <p>{content}</p>
                        {val.duplicate_news && val.duplicate_news.length > 0 && (
                            <>
                                <label className="report-f16" style={{ fontWeight: 'bold' }}>
                                    Similar News
                                </label>
                                {val.duplicate_news.map((item, index) => (
                                    <div key={index} className="pt-2">
                                        {item.title && (
                                            <div
                                                className="report-f16 ms-3 d-flex"
                                                style={{ color: 'black', fontWeight: 'bold' }}
                                            >
                                                <span style={{ color: 'rgb(25, 118, 210)' }}>#0{index + 1}</span>

                                                <span className="ms-2">{item.title}</span>
                                            </div>
                                        )}
                                        {item.snippet && (
                                            <div
                                                className="report-f14 ms-3"
                                                style={{
                                                    color: 'gray',
                                                }}
                                            >
                                                <div>{item.snippet}</div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </>
                        )}
                        {/* {otherSource.length > 0 && (
                            <div className="d-flex align-items-center">
                                {otherSource.map((val, index) => (
                                    <div>
                                        <a
                                            href={val.source ? val.source : ''}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ textDecoration: 'none', fontSize: '15px' }}
                                        >
                                            {val.source_name
                                                ? val.source_name
                                                : val.source
                                                      .match(
                                                          /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/gim
                                                      )[0]
                                                      .replace(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?/gim, '')}
                                        </a>
                                        {index !== otherSource.length - 1 && (
                                            <span
                                                style={{
                                                    borderLeft: '1.5px solid lightgray',
                                                    height: '100px',
                                                    marginRight: '10px',
                                                    marginLeft: '10px',
                                                    fontSize: '10px',
                                                }}
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                        )} */}
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={socialReadMore}
                    toggle={() => {
                        setSocialReadMore(!socialReadMore)
                    }}
                    size="lg"
                >
                    <div className="modal_header_black">
                        <span style={{ color: 'white' }}>{header}</span>
                        <span
                            onClick={() => {
                                setSocialReadMore(!socialReadMore)
                            }}
                            className="modal_close_btn"
                        >
                            <AiFillCloseCircle />
                        </span>
                    </div>

                    <ModalBody>
                        <p>{content}</p>
                    </ModalBody>
                </Modal>
            </div>
            <div
                style={{
                    width: emailNewsLoader ? '50%' : '',
                    border: isFindAndShare ? 'none' : '1px solid rgba(0,0,0,.125)',
                }}
            >
                {emailNewsLoader && (
                    <>
                        <label style={{ fontSize: '18px', fontWeight: 800, margin: '5px 0px 0px 10px' }}>
                            AI Recommendation Content
                            <span style={{ fontWeight: 500, fontSize: '16px', marginLeft: '10px' }}>
                                (
                                {showContentOf === 'EMAIL'
                                    ? 'Email Content'
                                    : showContentOf === 'LINKEDIN'
                                    ? 'Linkedin Content'
                                    : 'Twitter Content'}
                                )
                            </span>
                        </label>
                        <textarea
                            style={{
                                width: '100%',
                                height: '80%',
                                outline: 'none',
                                marginTop: '10px',
                                border: 'none',
                                borderBottom: '1px solid rgba(0,0,0,.125)',
                                resize: 'none',
                                borderTop: '1px solid rgba(0,0,0,.125)',
                            }}
                            id="emailNewsArea"
                            // value={''}
                            placeholder="Generating..."
                            readOnly
                        />
                        <div>
                            {showContentOf === 'EMAIL' && (
                                <a
                                    href={`mailto:?to=&body=${
                                        emailContent.email
                                            ? encodeURIComponent(
                                                  (emailContent.email &&
                                                      emailContent.email.substr(
                                                          emailContent.email.indexOf('\n') + 1
                                                      )) ||
                                                      ''
                                              ).replace(/\n/g, '%0D')
                                            : encodeURIComponent(
                                                  (result && result.substr(result.indexOf('\n') + 1)) || ''
                                              ).replace(/\n/g, '%0D')
                                    }%0D${emailContent.news_url}%0D&subject=${
                                        emailContent.email && emailContent.email.toLowerCase().includes('subject')
                                            ? encodeURIComponent(
                                                  emailContent.email.substr(0, emailContent.email.indexOf('\n')) || ''
                                              ).replace('Subject:', '')
                                            : emailContent.title
                                    }`}
                                >
                                    <button className="btn btn-primary" style={{ display: 'flex', margin: 'auto' }}>
                                        Send
                                    </button>
                                </a>
                            )}
                            {showContentOf === 'LINKEDIN' && (
                                <a
                                    href={`http://www.linkedin.com/share?mini=true&title=${encodeURIComponent(
                                        emailContent.title
                                    )}%0D&text=${encodeURIComponent(
                                        emailContent.linkedin ? emailContent.linkedin : result
                                    )}%0D${encodeURIComponent(emailContent.news_url)}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <button className="btn btn-primary" style={{ display: 'flex', margin: 'auto' }}>
                                        Post
                                    </button>
                                </a>
                            )}

                            {showContentOf === 'TWITTER' && (
                                <a
                                    href={`http://twitter.com/share?&title=${encodeURIComponent(
                                        emailContent.title
                                    )}%0D&url=${encodeURIComponent(emailContent.news_url)}%0D&text=${encodeURIComponent(
                                        emailContent.twitter ? emailContent.twitter : result
                                    )}%0D&hashtags=${emailContent.twitter ? '' : ''}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <button className="btn btn-primary" style={{ display: 'flex', margin: 'auto' }}>
                                        Post
                                    </button>
                                </a>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
