import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ToggleOnIcon from '@mui/icons-material/ToggleOn'
import ToggleOffIcon from '@mui/icons-material/ToggleOff'
import { RiDeleteBin6Line } from 'react-icons/ri'
import DataTable from 'react-data-table-component'
import DeleteNews from './DeleteNews'
import { toast } from 'react-toastify'
import store from '../../../Store'
import { AiOutlineSave } from 'react-icons/ai'
import CancelIcon from '@mui/icons-material/Cancel'
import SearchIcon from '@mui/icons-material/Search'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap'
import {
    Select,
    Chip,
    MenuItem,
    Grid,
    Autocomplete,
    TextField,
    createFilterOptions,
    Switch,
    Paper,
    ToggleButton,
    ToggleButtonGroup,
    InputAdornment,
} from '@mui/material'
import CronTab from '../../AnalystAdmin/CronTab'
import { Circles } from 'react-loader-spinner'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import { IoIosClose } from 'react-icons/io'
import NewSearchQueryResult from '../../../components/NewsEditedCard/NewSearchQueryResult'
import { LastCronValue } from '../../../utils/dashboardStrings'

const sections = [
    { label: 'Business Events', value: 'subcategories' },
    { label: 'Company', value: 'companies' },
    { label: 'Industry', value: 'industries' },
    { label: 'Sector', value: 'sectors' },
    { label: 'Topic', value: 'topics' },
]

const filter = createFilterOptions()

export default function NewSavedSearches(props) {
    const userId = store.getState().auth.user.id
    const userEmail = store.getState().auth.user.email
    const [isLoading, setIsLoading] = useState(false)
    const {
        selectedQueries,
        setSelectedQueries,
        setActiveTab,
        usertype,
        selectedSection,
        setSelectedSection,
        selectedUsers,
        setSelectedUsers,
        emailSubject,
        setEmailSubject,
        includeSection,
        setIncludeSection,
        includeBusiness,
        setIncludeBusiness,
        selectedLevel,
        setSelectedLevel,
        currentPath,
        setEditId,
        setSelectedOption,
        getAllCollections,
        setSaveQueryName,
    } = props

    const { editPage, setEditPage } = props
    const { editQueryName, setEditQueryName } = props
    const { directRun, setDirectRun } = props
    const { isQueryEditor, setIsQueryEditor } = props
    const { isQueryEditorJson, setIsQueryEditorJson } = props
    const { editorQuery, setEditorQuery, setRunQuery } = props
    const {
        allRegions,
        allCompanies,
        allSubCategories,
        allTopics,
        allIndustries,
        allSources,
        combinedRegCouPro,
    } = props
    const { setBasicSearchInput } = props
    const { handleQuickPopulate } = props
    const {
        collectionId,
        setCollectionId,
        collectionDescription,
        setCollectionDescription,
        collectionName,
        setCollectionName,
        collectionPrivacy,
        setCollectionPrivacy,
        collectionUsers,
        setCollectionUsers,
        allCollectionName,
        allCollectionUsers,
        collectionShareList,
        setFromFilter,
    } = props

    const {
        selectedStories,
        selectedArticles,
        setSelectedArticles,
        addToSelection,
        removeFromSelection,
        selectedNewsOnly,
        expandedView,
        isAllSelected,
        generateSummary,
        setAdvanceCurrentDate,
        rowsPerPage,
        setRowsPerPage,
    } = props

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: '#00193C', // Change to your desired header background color
                color: '#FFF', // Change to your desired header text color
                fontSize: '16px', // Change to your desired header font size
                height: '20px',
            },
        },
    }

    useEffect(() => {
        setIsLoading(true)
        getAlerts()
        getUsers()
        getTemplates()
    }, [])

    const getUsers = () => {
        axios.get('/endusers/firm-users/').then(res => {
            const filter = [userEmail]
            filter.forEach(email => {
                const existingUser = res.data.find(user => user.email === email)

                if (!existingUser) {
                    const newUser = {
                        id: email,
                        email: email,
                        full_name: email,
                    }

                    res.data.push(newUser)
                }
            })
            res.data.sort((a, b) => (a.email === userEmail ? -1 : b.email === userEmail ? 1 : 0))

            setUsers(res.data)
        })
    }

    const getTemplates = () => {
        axios.get('/endusers/templates/').then(response => {
            setTemplates(
                response.data
                    .filter(each => each.custom_template === false)
                    .map(each => {
                        return {
                            value: each.id,
                            label: each.name,
                            imageUrl: each.thumbnail,
                            has_sections: each.has_sections,
                        }
                    })
            )
        })
    }

    const [alerts, setAlerts] = useState([])
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [deleteId, setDeleteId] = useState({ id: null, query: '' })

    ///
    const [templates, setTemplates] = useState([])

    const [users, setUsers] = useState([])
    const [searchQueries, setSearchQueries] = useState([])
    const [selectedValues, setSelectedValues] = useState({})
    const [scheduleModel, setScheduleModal] = useState(false)
    const [scheduleContent, setScheduleContent] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [cronTabvalue, setCronTabValue] = useState('0 0 * * 1')
    const [isEnabled, setIsEnabled] = useState(false)
    const [search, setSearch] = useState('')

    const getAlerts = () => {
        axios
            .get('/news/search-query/')
            .then(res => {
                const savedData = res.data.filter(
                    val =>
                        val.query_string === null ||
                        (val.query_string === null && val.quick_search_query === null && val.query === null)
                )
                setAlerts(savedData)
                setIsLoading(false)
            })
            .catch(err => console.log(err))
    }

    const scheduling = row => {
        setScheduleContent(row)
        setScheduleModal(!scheduleModel)
        setTimeout(() => {
            // const parentElement = document.querySelector('.react-js-cron-hours')
            // const spanElement = parentElement.querySelector('span')

            // if (parentElement && spanElement && spanElement.textContent.trim() === 'at') {
            //     spanElement.innerHTML = 'at hour'
            // }
            setIsEnabled(row.scheduled)
            const currentTemplate = templates.filter(val => val.value === row.template)
            if (currentTemplate.length > 0) {
                setSelectedTemplate(currentTemplate[0])
            } else {
                setSelectedTemplate(null)
            }
            if (row.section) {
                const currentsection = sections.filter(val => val.value === row.section)
                setSelectedSection(currentsection[0])
            } else {
                setSelectedSection(null)
            }
            if (row.has_section) {
                setIncludeSection(true)
            } else {
                setIncludeSection(false)
            }
            if (row.include_subcategory) {
                setIncludeBusiness(true)
            } else {
                setIncludeBusiness(false)
            }
            if (row.subject) {
                if (row.subject !== '') {
                    setEmailSubject(row.subject)
                } else {
                    setEmailSubject(`${row.query_name + '-insights'}`)
                }
            } else {
                setEmailSubject(`${row.query_name + '-insights'}`)
            }
            if (row.recipients) {
                const resultArray = row.recipients.split(',')

                const filter = resultArray

                filter.forEach(email => {
                    const existingUser = users.find(user => user.email === email)

                    if (!existingUser) {
                        const newUser = {
                            id: email,
                            email: email,
                            full_name: email,
                        }

                        users.push(newUser)
                    }
                })

                const currentUser = users.filter(val => resultArray.includes(val.email))

                if (currentUser.length > 0) {
                    setSelectedUsers(currentUser)
                }
            } else {
                setSelectedUsers([])
            }
            if (row.cron_expression) {
                setCronTabValue(LastCronValue(row.cron_expression, '+'))
            } else {
                setCronTabValue('0 0 * * 1')
            }
        }, scheduleModel)
    }
    const alertcolumns = [
        {
            name: <label style={{ color: 'white' }}>Name</label>,
            cell: row => row.query_name,
            width: '40%',
        },
        {
            name: <label style={{ color: 'white' }}>Status</label>,
            cell: row =>
                row.scheduled ? (
                    <span className="d-flex align-items-center" style={{ color: '#077D55', marginLeft: '-10px' }}>
                        <span>
                            <ToggleOnIcon style={{ fontSize: 'x-large' }} />
                        </span>
                        <span style={{ marginLeft: '5px' }}>Enabled</span>
                    </span>
                ) : (
                    <span className="d-flex align-items-center" style={{ color: 'lightgray', marginLeft: '-10px' }}>
                        <span>
                            <ToggleOffIcon style={{ fontSize: 'x-large' }} />
                        </span>
                        <span style={{ marginLeft: '5px' }}>Disabled</span>{' '}
                    </span>
                ),
            width: '20%',
            textAlign: 'center',
        },
        {
            name: <label style={{ color: 'white' }}>Actions</label>,
            cell: row => (
                <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                    <span
                        style={{
                            textDecoration: 'underline',
                            color: '#186ADE',
                            cursor: 'pointer',
                            fontSize: '14px',
                            textDecoration: 'underline',
                        }}
                        onClick={() => {
                            const name = allCollectionName.filter(val => val.value === row.collection)

                            if (name.length > 0) {
                                setCollectionName(name[0])
                                setCollectionId(row.collection)
                                setCollectionDescription(name[0].description)
                                setCollectionPrivacy(collectionShareList.filter(val => val.value === name[0].shared)[0])
                                setCollectionUsers(allCollectionUsers.filter(item => name[0].users.includes(item.id)))
                            }

                            directRunHandle(row)
                        }}
                    >
                        Run/Edit
                    </span>
                    <span
                        style={{
                            textDecoration: 'underline',
                            color: '#186ADE',
                            cursor: 'pointer',
                            fontSize: '14px',
                            textDecoration: 'underline',
                            marginLeft: '10px',
                        }}
                        onClick={() => {
                            scheduling(row)
                        }}
                    >
                        Schedule Settings
                    </span>
                    <RiDeleteBin6Line
                        style={{
                            marginLeft: '10px',
                            color: '#FC3B3B',
                            cursor: 'pointer',
                            fontSize: '16px',
                            marginLeft: '10px',
                        }}
                        title="delete"
                        onClick={() => {
                            setDeleteModalOpen(!deleteModalOpen)
                            setDeleteId({
                                id: row.id,
                                query: row.query_name,
                            })
                        }}
                    />
                </div>
            ),
            textAlign: 'center',
        },
    ]

    const directRunHandle = value => {
        if (value.section) {
            setSelectedSection(value.section)
        }
        if (value.subject) {
            setEmailSubject(value.subject)
        }
        if (value.has_section) {
            setIncludeSection(value.has_section)
        }
        if (value.include_subcategory) {
            setIncludeBusiness(value.include_subcategory)
        }
        if (value.curation_level) {
            setSelectedLevel(value.curation_level)
        } else {
            setSelectedLevel(
                currentPath === '/app/search/' || currentPath === '/app/publish-search/' ? 'level2' : 'level1'
            )
        }
        if (value.collection) {
            setCollectionId(value.collection)
        }
        if (value.collection_name) {
            setCollectionName(allCollectionName.filter(val => val.label === value.collection_name)[0])
        }
        if (value.collection_description) {
            setCollectionDescription(value.collection_description)
        }
        if (value.collection_privacy) {
            setCollectionPrivacy(collectionShareList.filter(val => val.value === value.collection_privacy)[0])
        }
        if (value.collection_users) {
            setCollectionUsers(allCollectionUsers.filter(user => value.collection_users.some(f => f.value === user.id)))
        }
        if (value.recipients) {
            const resultArray = value.recipients.split(',')
            const filter = resultArray
            filter.forEach(email => {
                const existingUser = users.find(user => user.email === email)
                if (!existingUser) {
                    const newUser = {
                        id: email,
                        email: email,
                        full_name: email,
                    }
                    users.push(newUser)
                }
            })
            const currentUser = users.filter(val => resultArray.includes(val.email))
            if (currentUser.length > 0) {
                setSelectedUsers(currentUser)
            }
        }
        if (value.quick_search_query !== null) {
            setActiveTab(0)
            // setEditPage(true)
            setDirectRun(true)
            setEditQueryName(value.query_name)
            setBasicSearchInput(value.quick_search_query)
            setEditId(value.id)
            setSelectedSection(value.section)
            setIncludeBusiness(value.include_subcategory)
            setIncludeSection(value.has_section)
            if (value.query && value.query.filters) {
                const populate = value.query.filters

                const sourcesFromSaved = populate.source ? populate.source.values.map(val => val) : []
                const regionfromSaved = populate.countries ? populate.countries.values.map(val => val) : []
                const companyfromSaved = populate.company ? populate.company.values.map(val => val) : []
                const sectorFromSaved = populate.sectors ? populate.sectors.values.map(val => val) : []
                const categoryFromSaved = populate.category ? populate.category.values.map(val => val) : []
                const topicFromSaved = populate.topics ? populate.topics.values.map(val => val) : []
                const dateFromSaved = populate.date ? populate.date : '29'
                setSelectedOption(dateFromSaved)

                handleQuickPopulate(
                    sourcesFromSaved,
                    regionfromSaved,
                    companyfromSaved,
                    sectorFromSaved,
                    categoryFromSaved,
                    topicFromSaved,
                    dateFromSaved
                )

                setFromFilter([
                    ...sourcesFromSaved,
                    ...companyfromSaved,
                    ...sectorFromSaved,
                    ...categoryFromSaved,
                    ...topicFromSaved,
                ])
            }
        } else {
            directRunResult(value)
        }
    }

    const directRunResult = value => {
        setActiveTab(1)
        // setIsQueryEditor(false)
        // setEditPage(true)
        setDirectRun(true)
        setEditId(value.id)
        setEditQueryName(value.query_name)
        if (value.query && value.query.filters) {
            const populate = value.query.filters
            const sourcesFromSaved = populate.source ? populate.source.values.map(val => val) : []
            const regionfromSaved = populate.countries ? populate.countries.values.map(val => val) : []
            const companyfromSaved = populate.company ? populate.company.values.map(val => val) : []
            const sectorsFromSaved = populate.sectors ? populate.sectors.values.map(val => val) : []
            const categoryFromSaved = populate.category ? populate.category.values.map(val => val) : []
            const topicFromSaved = populate.topics ? populate.topics.values.map(val => val) : []
            const savedNewsContent = populate.newsContent ? populate.newsContent.regex : ''
            const savedRegion = combinedRegCouPro.filter(val => regionfromSaved.includes(val.label))
            const savedCompany = allCompanies.filter(val => companyfromSaved.includes(val.label))
            const savedTopic = allTopics.filter(val => topicFromSaved.includes(val.label))
            const savedCategory = allSubCategories.filter(val => categoryFromSaved.includes(val.label))
            const savedSectors = allIndustries.filter(val => sectorsFromSaved.includes(val.label))
            const savedSources = allSources.filter(val => sourcesFromSaved.includes(val.value))

            // Define the start and end dates
            var startDateCount = new Date(populate.articleDate.from)
            var endDateCount = new Date(populate.articleDate.to)

            // Calculate the difference in milliseconds
            var diffInTime = endDateCount.getTime() - startDateCount.getTime()

            // Convert the difference from milliseconds to days
            var diffInDays = Math.floor(diffInTime / (1000 * 60 * 60 * 24))

            setAdvanceCurrentDate(Number(isNaN(diffInDays)) === NaN ? 29 : diffInDays)
            setSelectedQueries({
                ...selectedQueries,
                articleDateFrom: populate.articleDate.from,
                articleOperator: populate.articleDate.operator,
                articleDateTo: populate.articleDate.to,
                region: savedRegion,
                regionOperator: populate.countries.operator,
                sectors: savedSectors,
                industryOperator: populate.sectors.operator,
                company: savedCompany,
                companyOperator: populate.company.operator,
                category: savedCategory,
                categoryOperator: populate.category.operator,
                topic: savedTopic,
                topicOperator: populate.topics.operator,
                newsTitle: populate.newsTitle ? populate.newsTitle.value : '',
                newsTitleOperator: populate.newsTitle ? populate.newsTitle.operator : 'CONTAINS',
                newsContent: savedNewsContent.substring(0, savedNewsContent.length - 1),
                newsContentOperator: populate.newsContent ? populate.newsContent.operator : 'CONTAINS',
                source: savedSources,
                sourceOperator: populate.source.operator,
            })
        }
    }

    const deleteSavedQuery = () => {
        axios
            .delete(`/news/search-query/${deleteId.id}/`)
            .then(res => {
                getAlerts()
                toast('Query was successfully deleted.')
            })
            .catch(err => console.log(err))
            .finally(() => {
                setDeleteModalOpen(false)
            })
    }

    const saveChanges = row => {
        const payload = {
            id: row.id,
            query: row.query,
            query_name: row.query_name,
            template: selectedTemplate && selectedTemplate.value ? selectedTemplate.value : null,
            user_recipients: selectedUsers.map(val => val.id).filter(val => !isNaN(val) && typeof val === 'number'),
            last_runtime: row.last_runtime,
            scheduled: isEnabled,
            cron_expression: LastCronValue(cronTabvalue, '-'),
            recipients: selectedUsers.map(val => val.email),
            newsletter: 'saved_search_newsletter',
            section:
                selectedTemplate && selectedTemplate.has_sections && selectedSection ? selectedSection.value : null,
            query_string: row.query_string,
            quick_search_query: row.quick_search_query,
            subject: emailSubject,
            has_section: includeSection,
            include_subcategory: includeBusiness,
            curation_level: selectedLevel,
        }
        if (isEnabled) {
            if (
                selectedTemplate &&
                selectedTemplate.value &&
                selectedUsers.length > 0 &&
                emailSubject !== ''
                //  &&
                // ((selectedTemplate.has_sections && selectedSection) || !selectedTemplate.has_sections)
            ) {
                if (row.apscheduler_id !== null) {
                    payload.apscheduler_id = row.apscheduler_id
                }
                axios
                    .put(`/news/search-query/${row.id}/`, payload)
                    .then(res => {
                        toast('Query has been successfully updated')
                        setScheduleModal(!scheduleModel)
                        getAlerts()
                    })
                    .catch(err => console.log(err))
            } else {
                toast.warn('Please fill out all the fields.')
            }
        } else {
            if (row.apscheduler_id !== null) {
                payload.apscheduler_id = row.apscheduler_id
            }
            axios
                .put(`/news/search-query/${row.id}/`, payload)
                .then(res => {
                    toast('Query has been successfully updated')
                    setScheduleModal(!scheduleModel)
                    getAlerts()
                })
                .catch(err => console.log(err))
        }
    }

    const SearchField = () => {
        return (
            <TextField
                value={search}
                onChange={e => {
                    setSearch(e.target.value)
                }}
                placeholder="Search here..."
                variant="outlined"
                sx={{
                    width: '25%',
                    background: 'white',
                    borderRadius: '5px',
                }}
                size="small"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        )
    }

    return (
        <div style={{ padding: '40px 20px' }}>
            {isLoading ? (
                <Circles key={0} type="Circles" color="#adadad" height={80} width={80} />
            ) : (
                <>
                    {alerts.length !== 0 && SearchField()}
                    <div
                        style={{
                            marginTop: '20px',
                            width: selectedStories.length === 0 ? '100%' : selectedArticles ? '78%' : '95%',
                        }}
                    >
                        <div>
                            <DataTable
                                columns={alertcolumns}
                                data={
                                    search === ''
                                        ? alerts
                                        : alerts.filter(each =>
                                              each.query_name.toLowerCase().includes(search.toLowerCase())
                                          )
                                }
                                pagination
                                paginationPerPage={rowsPerPage}
                                onChangeRowsPerPage={currentRowsPerPage => setRowsPerPage(currentRowsPerPage)}
                                fixedHeader
                                customStyles={customStyles}
                                noHeader={true}
                            />
                        </div>
                        {selectedStories.length > 0 && (
                            <>
                                {!selectedArticles && (
                                    <>
                                        <div
                                            className="advance_section"
                                            style={{
                                                height:
                                                    document.documentElement.scrollHeight > 1500
                                                        ? document.documentElement.scrollHeight
                                                        : '90vh',
                                                background: '#F2F5F7',
                                                transition: 'opacity 3s ease',
                                                overflow: 'auto',
                                                width: '50px',
                                                cursor: 'pointer',
                                                marginTop: '-50px',
                                                zIndex: 8,
                                                boxShadow: '-1px 0 1px -1px rgba(0, 0, 0, 0.5)',
                                                position: 'fixed',
                                                right: 0,
                                                top: '122px',
                                            }}
                                        >
                                            <KeyboardDoubleArrowLeftIcon
                                                style={{ marginTop: '10px' }}
                                                onClick={() => {
                                                    setSelectedArticles(true)
                                                }}
                                            />
                                            <hr style={{ margin: '10px 0px' }} />
                                        </div>
                                    </>
                                )}

                                {selectedArticles && (
                                    <>
                                        {/* <div
                                                className="advance_section"
                                                style={{
                                                    height:
                                                        document.documentElement.scrollHeight > 1500
                                                            ? document.documentElement.scrollHeight
                                                            : '100vh',
                                                    background: '#F2F5F7',
                                                    transition: 'opacity 3s ease',
                                                    overflow: 'auto',
                                                    width: '260px',
                                                    boxShadow: '-1px 0 1px -1px rgba(0, 0, 0, 0.5)',
                                                }}
                                            /> */}
                                        <div
                                            className="advance_section"
                                            style={{
                                                height:
                                                    document.documentElement.scrollHeight > 1500
                                                        ? document.documentElement.scrollHeight
                                                        : '90vh',
                                                background: '#F2F5F7',
                                                transition: 'opacity 3s ease',
                                                overflow: 'auto',
                                                width: '260px',
                                                position: 'fixed',
                                                right: 0,
                                                top: '70px',
                                                zIndex: 8,
                                                boxShadow: '-1px 0 1px -1px rgba(0, 0, 0, 0.5)',
                                            }}
                                        >
                                            <div>
                                                <div
                                                    className="d-flex justify-content-between align-items-center"
                                                    style={{ marginTop: '10px', cursor: 'pointer' }}
                                                >
                                                    <KeyboardDoubleArrowRightIcon
                                                        onClick={() => {
                                                            setSelectedArticles(false)
                                                        }}
                                                    />

                                                    <label style={{ fontSize: '16px' }}>Selected Articles</label>
                                                    <span
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setSelectedArticles(false)
                                                        }}
                                                    >
                                                        <IoIosClose style={{ fontSize: '26px' }} />
                                                    </span>
                                                </div>
                                                <hr style={{ margin: '10px 0px' }} />
                                                <div>
                                                    {selectedStories.map(story => {
                                                        return (
                                                            <NewSearchQueryResult
                                                                isFindAndShare={true}
                                                                key={story.id}
                                                                EachrawNews={story}
                                                                isBasicSearch={true}
                                                                selectedStories={selectedStories}
                                                                isStorySelected={
                                                                    selectedStories.findIndex(
                                                                        arr => arr.id === story.id
                                                                    ) === -1
                                                                        ? false
                                                                        : true
                                                                }
                                                                addToSelection={addToSelection}
                                                                // allCategories={allCategories}
                                                                allSubCategories={allSubCategories}
                                                                allIndustries={allIndustries}
                                                                allCompanies={allCompanies}
                                                                // setAllCompanies={setAllCompanies}
                                                                allRegions={allRegions}
                                                                allLinkedInGroups={[]}
                                                                removeFromSelection={removeFromSelection}
                                                                allTopics={allTopics}
                                                                collapsed={!expandedView}
                                                                usertype={usertype}
                                                                selectAll={isAllSelected}
                                                                currentPath={currentPath}
                                                                selectedNewsOnly={selectedNewsOnly}
                                                                isScore={false}
                                                                selectedLevel={selectedLevel}
                                                                selectedOnly={true}
                                                                generateSummary={generateSummary}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </>
            )}
            {deleteModalOpen && (
                <DeleteNews
                    deleteModalOpen={deleteModalOpen}
                    queryName={deleteId.query}
                    setDeleteModalOpen={setDeleteModalOpen}
                    savedQueryDelete={true}
                    handleBatchDeleteStories={deleteSavedQuery}
                />
            )}
            <Modal
                isOpen={scheduleModel}
                toggle={() => {
                    setScheduleModal(!scheduleModel)
                }}
                size="lg"
            >
                <div
                    style={{ padding: '20px 10px 0px 10px' }}
                    className="d-flex justify-content-between  align-items-center"
                >
                    <h3 style={{ paddingLeft: '2%', fontWeight: 600 }}>Scheduling NewsLetter</h3>

                    <span
                        style={{ paddingRight: '2%', cursor: 'pointer' }}
                        onClick={() => {
                            setScheduleModal(!scheduleModel)
                        }}
                    >
                        <CancelIcon />
                    </span>
                </div>
                <hr />
                <ModalBody style={{ padding: '0px 10px' }}>
                    <div
                        style={{
                            width: '320px',
                            border: '1px solid lightgray',
                            height: '50px',
                            marginLeft: '10px',
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div className="form-check form-switch" style={{ marginLeft: '10px' }}>
                            <input
                                style={{ cursor: 'pointer' }}
                                title="enabled/disabled of scheduling"
                                className="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckChecked"
                                value={isEnabled}
                                checked={isEnabled ? true : false}
                                onChange={e => {
                                    setIsEnabled(current => !current)
                                }}
                            />
                            <label className="form-check-label text-black" htmlFor="flexSwitchCheckChecked">
                                {isEnabled ? 'Scheduling Enabled' : 'Scheduling Disabled'}
                            </label>
                        </div>
                    </div>

                    <div className="d-flex" style={{ padding: '10px' }}>
                        <div>
                            <div style={{ marginTop: '5px' }}>
                                <TextField
                                    disabled={isEnabled ? false : true}
                                    sx={{ width: '320px' }}
                                    multiline
                                    rows={3}
                                    value={emailSubject}
                                    onChange={e => {
                                        setEmailSubject(e.target.value)
                                    }}
                                    variant="outlined"
                                    label="Email subject"
                                />
                            </div>
                            <div style={{ marginTop: '15px' }}>
                                <Autocomplete
                                    disabled={isEnabled ? false : true}
                                    sx={{ width: '320px' }}
                                    options={templates}
                                    autoHighlight
                                    value={selectedTemplate}
                                    getOptionLabel={option => option.label}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            <img
                                                src={option.imageUrl}
                                                alt={option.label}
                                                style={{ width: '24px', marginRight: '8px' }}
                                            />
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            sx={{ height: '40px' }}
                                            style={{ height: 40 }}
                                            label="Choose a newsletter"
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        setSelectedTemplate(value)
                                        if (value === null || value.has_sections === false) {
                                            setIncludeSection(false)
                                        }
                                    }}
                                />
                            </div>

                            {/* Includes Sections and Business Events */}
                            <div style={{ marginTop: '25px' }}>
                                <div className="form-check form-switch">
                                    <label className="form-check-label text-black">
                                        {'Include Business Events in the Newsletter'}
                                    </label>
                                    <input
                                        style={{ cursor: 'pointer' }}
                                        title="include/exclude of business events in the newsletter"
                                        className="form-check-input"
                                        type="checkbox"
                                        value={includeBusiness}
                                        checked={includeBusiness ? true : false}
                                        onChange={e => {
                                            setIncludeBusiness(current => !current)
                                        }}
                                        disabled={isEnabled ? false : true}
                                    />
                                </div>
                                {selectedTemplate && selectedTemplate.has_sections && (
                                    <>
                                        <div className="form-check form-switch">
                                            <label className="form-check-label text-black">
                                                {'Include Section in the Newsletter'}
                                            </label>
                                            <input
                                                style={{ cursor: 'pointer' }}
                                                title="include/exlude of Section in the newsletter"
                                                className="form-check-input"
                                                type="checkbox"
                                                value={includeSection}
                                                checked={includeSection ? true : false}
                                                onChange={e => {
                                                    setIncludeSection(current => !current)
                                                }}
                                                disabled={isEnabled ? false : true}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>

                            {/* section */}
                            {selectedTemplate !== null && selectedTemplate.has_sections && includeSection && (
                                <div style={{ marginTop: '10px' }}>
                                    <Autocomplete
                                        disabled={isEnabled ? false : true}
                                        sx={{ width: '320px' }}
                                        options={sections}
                                        autoHighlight
                                        aria-required={selectedTemplate.has_sections ? true : false}
                                        value={selectedSection}
                                        getOptionLabel={option => option.label}
                                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                sx={{ height: '40px' }}
                                                style={{ height: 40 }}
                                                label="Choose a section type"
                                            />
                                        )}
                                        onChange={(event, value) => {
                                            setSelectedSection(value)
                                            if (value === null) {
                                                setIncludeSection(false)
                                            }
                                        }}
                                    />
                                </div>
                            )}
                            {/* users */}
                            <div
                                style={{
                                    marginTop: includeSection ? '25px' : '15px',
                                }}
                            >
                                <Autocomplete
                                    disabled={isEnabled ? false : true}
                                    sx={{ width: '320px' }}
                                    multiple
                                    options={users}
                                    value={selectedUsers}
                                    getOptionLabel={option => {
                                        if (option.label) {
                                            return option.label
                                        }

                                        return option.email
                                    }}
                                    onChange={(event, newValue) => {
                                        if (newValue && newValue.length) {
                                            const results = []

                                            newValue.forEach(value => {
                                                if (typeof value === 'string') {
                                                    function ValidateEmail(mail) {
                                                        return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)
                                                    }

                                                    if (!ValidateEmail(value)) {
                                                        toast.error(
                                                            `You have entered an invalid email address! ${value}`
                                                        )
                                                        return
                                                    }

                                                    return results.push({
                                                        email: value,
                                                    })
                                                }

                                                return results.push(value)
                                            })

                                            setSelectedUsers(results)
                                        }
                                    }}
                                    renderInput={params => (
                                        <TextField {...params} variant="outlined" label="Recipients" />
                                    )}
                                    renderTags={() => null}
                                    freeSolo
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params)
                                        const { inputValue } = params
                                        const isExisting = options.some(option => inputValue === option.email)
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                id: `Add email :   ${inputValue}`,
                                                email: inputValue,
                                            })
                                        }

                                        return filtered
                                    }}
                                    renderOption={(props, option) => (
                                        <li
                                            {...props}
                                            style={{
                                                color: option.email === userEmail ? '#197bbd' : '',
                                                title: option.email === userEmail ? 'User' : 'exiting user',
                                            }}
                                        >
                                            <span>{option.label || option.email}</span>
                                            {usertype !== 'Regular User' && option.user_category === 'LiteUser' && (
                                                <span
                                                    className="Lite"
                                                    style={{
                                                        marginLeft: '5px',
                                                        paddingLeft: '5px',
                                                        fontSize: 'small',
                                                        background: 'skyblue',
                                                        padding: '2px 5px',
                                                        borderRadius: '5px',
                                                    }}
                                                >
                                                    Lite*
                                                </span>
                                            )}
                                        </li>
                                    )}
                                />
                            </div>
                        </div>

                        {selectedUsers.length > 0 && (
                            <div
                                style={{
                                    marginTop: '-60px',
                                    marginLeft: '20px',
                                    padding: '10px',
                                    width: '400px',
                                    height:
                                        selectedTemplate !== null && selectedTemplate.has_sections ? '377px' : '300px',
                                    overflow: 'auto',
                                    border: '1px solid lightgray',
                                    borderRadius: '4px',
                                }}
                            >
                                <label style={{ fontSize: '16px' }}>Selected Users</label>
                                <Grid item>
                                    <Grid
                                        container
                                        direction="row"
                                        spacing={1}
                                        style={{ marginTop: '5px', borderTop: '1px solid lightgray' }}
                                    >
                                        {selectedUsers.map(user => (
                                            <Grid item>
                                                <Chip
                                                    key={user.id}
                                                    label={user.email}
                                                    style={{
                                                        background: typeof user.id === 'number' ? '' : '#cdc8c7',
                                                    }}
                                                    title={
                                                        typeof user.id === 'number' ? 'Existing user' : 'Custom user'
                                                    }
                                                    disabled={isEnabled ? false : true}
                                                    onDelete={() =>
                                                        setSelectedUsers(
                                                            selectedUsers.filter(currUser => currUser.id !== user.id)
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                    </div>
                    <div className="d-flex justify-content-between" style={{ padding: '10px' }}>
                        <div className="cron_tab" style={{ marginLeft: '5px' }}>
                            {/* <label style={{ fontSize: '16px' }}>Select Schedule Timing</label> */}
                            <div style={{ marginTop: '10px' }}>
                                <CronTab
                                    isEnabled={isEnabled}
                                    setIsEnabled={setIsEnabled}
                                    cronTabValue={cronTabvalue}
                                    setCronTabValue={setCronTabValue}
                                    // isAssistant={true}
                                />
                            </div>
                        </div>
                    </div>

                    <ModalFooter style={{ padding: '10px' }}>
                        <button
                            variant="contained"
                            endIcon={<AiOutlineSave style={{ fontSize: '18px' }} />}
                            style={{
                                background: '#186ADE',
                                borderRadius: '4px',
                                width: 'max-content',
                                marginRight: '40px',
                                fontSize: '14px',
                                height: '40px',
                                color: 'white',
                                boxShadow: 'none',
                                textTransform: 'none',
                            }}
                            className="btn btn-primary"
                            onClick={() => {
                                saveChanges(scheduleContent)
                            }}
                            title="save"
                        >
                            Save
                        </button>
                    </ModalFooter>
                </ModalBody>
            </Modal>
        </div>
    )
}
