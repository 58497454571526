import React from 'react'
import { Modal } from 'reactstrap'
import { AiFillCloseCircle } from 'react-icons/ai'

export default function ConversationModal(props) {
    const { openModal, setOpenModal, News } = props

    return (
        <div>
            <Modal
                isOpen={openModal}
                toggle={() => {
                    setOpenModal(false)
                }}
                size="lg"
            >
                <div className="modal_header_black">
                    <span style={{ color: 'white', marginLeft: '20px' }}>Conversation Starters</span>

                    <div>
                        <span
                            onClick={() => {
                                setOpenModal(false)
                            }}
                            className="modal_close_btn"
                        >
                            <AiFillCloseCircle />
                        </span>
                    </div>
                </div>
                <div className="p-2">
                    <div>
                        {News.map((item, index) => (
                            <div key={index} className="p-2">
                                {item.Name && (
                                    <div
                                        className="report-f16 ms-3 d-flex"
                                        style={{ color: 'black', fontWeight: 'bold' }}
                                    >
                                        <span style={{ color: 'rgb(25, 118, 210)' }}>#0{index + 1}</span>

                                        <span className="ms-2">{item.Name}</span>
                                    </div>
                                )}
                                {item.Summary && (
                                    <div
                                        className="report-f14 ms-3"
                                        style={{
                                            color: 'gray',
                                        }}
                                    >
                                        <div>{item.Summary}</div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </Modal>
        </div>
    )
}
