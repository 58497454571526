import React, { useState } from 'react'
import CompanyLogo from '../../../components/CompanyLogo/CompanyLogo'
import DownCarrotLarge from '../../../assets/icons/down-carrot-large.svg'
import { CgNotes } from 'react-icons/cg'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

export default function Description(props) {
    const { Name, Logo, Overview, windowWidth, companyId, screenType, companyUrl } = props
    const { Industry, Revenue, Share, Headquaters } = props
    const [boxExpand, setBoxExpand] = useState(windowWidth > 900 ? true : false)
    const [descExpanded, setDescExpanded] = useState(false)

    const CompanyInfoLine = props => {
        return (
            <div
                className="companyOverview__keyValueBox"
                style={{
                    padding: '16px',
                    border: '1px solid #ececec',
                    // borderRadius: '4px',
                    backgroundColor: 'white',
                    boxShadow: '0 2px 5px whitesmoke',
                    // margin: '0px 80px 0px 120px',
                    // padding: '15px',
                }}
            >
                <p className="companyOverview__key">{props.first}</p>
                <p className="companyOverview__value">{props.second}</p>
            </div>
        )
    }

    return (
        <div className="companyOverview__descriptionContainer">
            <div
                className="companyOverview__heading"
                // onClick={windowWidth > 900 ? null : () => setBoxExpand(!boxExpand)}
                style={{ width: '100%' }}
            >
                <div
                    className="companyOverview__logoAndName"
                    id="companyOverview__logoAndName"
                    style={{ width: '100%' }}
                >
                    <CompanyLogo
                        logo={Logo}
                        name={Name}
                        id={companyId}
                        title_link={null}
                        OuterStyle={{ maxWidth: '56px', maxHeight: '56px', margin: '0 24px' }}
                        square={true}
                    />

                    <div className="companyOverview__nameAndDesc">
                        <p className="companyOverview__companyName text-black">{Name}</p>
                        {companyUrl && (
                            <a
                                href={
                                    companyUrl.split(';')[0].includes('http')
                                        ? companyUrl.split(';')[0]
                                        : `https://${companyUrl.split(';')[0]}`
                                }
                                style={{ color: 'black', textDecoration: 'none' }}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <p>{companyUrl.split(';')[0]}</p>
                            </a>
                        )}
                    </div>
                    {screenType === 'companies' && (
                        <button
                            title="Pre-meeting brief"
                            className="d-flex align-items-center justify-content-center ms-auto"
                            style={{
                                marginBottom: '0px',
                                background: 'rgb(25, 118, 210)',
                                marginLeft: 'auto',
                                color: 'white',
                                fontSize: '14px',
                                padding: '10px',
                                borderRadius: '4px',
                            }}
                            onClick={props.getPreMeetingData}
                        >
                            Generate Meeting Brief
                            {/* <CgNotes style={{ fontSize: '24', color: 'white' }} /> */}
                        </button>
                    )}
                </div>

                {/* <div className={boxExpand ? 'companyOverview__expand-rotate' : 'companyOverview__expand'}>
                    <img src={DownCarrotLarge} alt="click to expand" />
                </div> */}
            </div>

            <div className="companyOverview__descriptionBox">
                <div>
                    <span
                        style={{
                            cursor: 'pointer',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitLineClamp: descExpanded ? 'unset' : 3,
                            WebkitBoxOrient: 'vertical',
                            fontSize: '14px',
                            marginTop: '5px',
                        }}
                    >
                        {Overview.split('\n').map((article, index) => (
                            <div key={index}>{article}</div>
                        ))}
                    </span>
                    {Overview.length > 300 && (
                        <span
                            style={{ color: 'rgb(25, 118, 210)', cursor: 'pointer', marginTop: '5px' }}
                            onClick={() => {
                                setDescExpanded(!descExpanded)
                            }}
                            className="d-flex align-items-center"
                        >
                            <span style={{ fontWeight: 600, fontSize: '14px' }}>
                                {descExpanded ? 'See Less' : 'See More'}
                            </span>
                            {descExpanded ? (
                                <FaChevronUp
                                    style={{
                                        fontSize: '12px',
                                        marginLeft: '4px',
                                    }}
                                />
                            ) : (
                                <FaChevronDown
                                    style={{
                                        fontSize: '12px',
                                        marginLeft: '4px',
                                    }}
                                />
                            )}
                        </span>
                    )}
                </div>
            </div>

            <div
                style={{
                    background: 'white',
                    // display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    // gap: '16px',
                    rowGap: '0px',
                    columnGap: '1px',
                    justifyContent: 'center',
                    padding: '8px 40px 8px 120px',
                }}
                className="d-md-grid d-sm-block"
            >
                {Industry && Industry.length > 0 && <CompanyInfoLine first="Industry" second={Industry[0].name} />}
                {(Revenue || Revenue !== 0) && <CompanyInfoLine first="Revenue" second={Revenue} />}
                {Share && <CompanyInfoLine first="Type" second={Share} />}
                {Headquaters && <CompanyInfoLine first="Headquater" second={Headquaters} />}
            </div>
        </div>
    )
}
