import React, { Component } from 'react'
import './NewsEditCard2020.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { getFullFormattedDate } from '../../utils/dateHelpers'
import axios from 'axios'
export default class DuplicateNewsItems extends Component {
    state = {
        title: this.props.EachrawNews.title,
        snippet: this.props.EachrawNews.snippet,
        duplicateNewsCollapsed: false,
        snippet: false,
        alreadyGenerated: false,
        summaryLoader: false,
    }

    generateSummary = (index, id, snippet, callback) => {
        console.log('sni', index, id, snippet)

        if (index && index.includes('level0') && !this.state.alreadyGenerated) {
            this.setState({ summaryLoader: true })
            // this.props.generateSummary && this.props.generateSummary(true)

            const payload = {
                id: id,
                snippet: snippet,
            }

            axios
                .post('/core/summarise-article/', payload)
                .then(res => {
                    this.setState({ snippet: res.data })
                    this.setState({ alreadyGenerated: true })
                    if (callback) {
                        callback(res.data) // Call the callback with the new snippet
                    }
                })
                .catch(err => console.log(err))
                .finally(() => {
                    this.setState({ summaryLoader: false })
                    // this.props.generateSummary && this.props.generateSummary(false)
                })
        }
    }

    render() {
        const { selectedOnly, isAssisstant, isDuplicateSelected, lastIndex } = this.props
        const { source_name, source, date, score, index_name, id, snippet } = this.props.EachrawNews
        const { duplicateNewsCollapsed } = this.state
        return (
            <div>
                <div
                    className="d-flex align-items-center justify-content-between"
                    style={{
                        padding: lastIndex ? '0px 5px 5px 5px' : '0px 5px',
                        background: selectedOnly ? '#F2F5F7' : '#f2f5f7',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        this.generateSummary(index_name, id, snippet)
                    }}
                >
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                            marginTop: selectedOnly ? '10px' : '0px',
                            width: '60%',
                        }}
                    >
                        {!isAssisstant && (
                            <div
                                className={` ${isDuplicateSelected ? 'newsCard__select-active' : 'newsCard__select'}`}
                                style={{
                                    border: 'solid 1.5px #3E5463',
                                    marginTop: '5px',
                                }}
                                onClick={
                                    isDuplicateSelected
                                        ? () => this.props.removeFromSelection(this.props.EachrawNews)
                                        : () => this.props.addToSelection(this.props.EachrawNews)
                                }
                            />
                        )}

                        {this.state.title && (
                            <div
                                style={{
                                    fontWeight: 600,
                                    overflow: 'hidden',
                                    display: '-webkit-box',
                                    WebkitLineClamp: duplicateNewsCollapsed ? 2 : 1,
                                    WebkitBoxOrient: 'vertical',
                                    fontSize: selectedOnly ? '10px' : '',
                                    marginLeft: '20px',
                                    marginTop: '7px',
                                }}
                                title={this.state.title}
                                className={selectedOnly ? '' : 'font'}
                                onClick={() => {
                                    this.generateSummary(index_name, id, snippet)
                                    this.setState({ duplicateNewsCollapsed: !duplicateNewsCollapsed })
                                }}
                            >
                                {this.state.title}
                            </div>
                        )}
                    </div>
                    {(source_name || source) && (
                        <div
                            style={{
                                marginTop: '2px',
                                textAlign: 'left',
                                width: '12%',
                                overflow: !duplicateNewsCollapsed ? 'hidden' : '',
                            }}
                        >
                            <a
                                href={source ? source : ''}
                                style={{ color: 'black', fontSize: '14px', textAlign: 'left' }}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {source_name
                                    ? source_name
                                    : source
                                          .match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/gim)[0]
                                          .replace(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?/gim, '')}
                            </a>
                        </div>
                    )}
                    <div style={{ fontSize: '14px', textAlign: 'left', width: '10%' }}>
                        {!duplicateNewsCollapsed && getFullFormattedDate(date)}
                    </div>
                    <div
                        onClick={() => {
                            this.setState({ duplicateNewsCollapsed: !duplicateNewsCollapsed })
                        }}
                    >
                        {!duplicateNewsCollapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                    </div>
                </div>

                {duplicateNewsCollapsed && (
                    <div
                        style={{
                            padding: '3px 27px',
                            fontSize: '13px',
                            backgroundColor: '#f2f5f7',
                        }}
                    >
                        <div style={{ fontSize: '14px' }}>
                            {this.state.summaryLoader ? (
                                <div
                                    style={{
                                        padding: '15px',
                                        borderRadius: '2px',
                                        cursor: 'pointer',
                                        margin: '5px',
                                        display: 'flex',
                                        height: '80px',
                                        justifyContent: 'center',
                                        border: '1px solid black',
                                        paddingTop: '25px',
                                    }}
                                    className="font"
                                >
                                    <span className="round_loader" />{' '}
                                    <span style={{ marginLeft: '10px' }} className="font">
                                        Generate summary...
                                    </span>
                                </div>
                            ) : (
                                this.state.snippet
                            )}
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div style={{ color: '#0D4EA6' }}>{getFullFormattedDate(date)}</div>
                            {score && <div>Relevance Score: {score}</div>}
                        </div>
                    </div>
                )}
            </div>
        )
    }
}
