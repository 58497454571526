import React, { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

const ConversationStarters = props => {
    const { content, index } = props
    const [openConversation, setOpenConversation] = useState(false)

    const toggleConversation = () => {
        setOpenConversation(!openConversation)
    }

    return (
        <>
            <div key={index} style={{ width: '100%', padding: '15px 25px 0px 25px' }}>
                <div
                    style={{ fontWeight: 'bold', cursor: 'pointer   ' }}
                    className="d-flex align-items-center"
                    onClick={toggleConversation}
                >
                    <span style={{ color: 'rgb(25, 118, 210)' }}>#0{index + 1}</span>
                    <span
                        style={{
                            borderLeft: '1.5px solid gray',
                            height: '13px',
                            margin: '0px 10px',
                            fontSize: '10px',
                        }}
                    />
                    <span
                        style={{
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical',
                        }}
                    >
                        {content.Name}
                    </span>
                    <span style={{ marginLeft: 'auto' }}>
                        {openConversation ? (
                            <KeyboardArrowDownIcon style={{ fontSize: '20px' }} />
                        ) : (
                            <KeyboardArrowRightIcon style={{ fontSize: '20px' }} />
                        )}
                    </span>
                </div>
                <div
                    style={{
                        overflow: 'hidden',
                        display: openConversation ? 'block' : '-webkit-box',
                        WebkitLineClamp: openConversation ? 'unset' : 2,
                        WebkitBoxOrient: 'vertical',
                        fontSize: '15px',
                        color: 'gray',
                        marginTop: '10px',
                    }}
                >
                    {content.Summary}
                </div>
            </div>
        </>
    )
}

export default ConversationStarters
