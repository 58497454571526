import React, { useEffect, useRef, useState } from 'react'
import { Input, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { Button } from '@mui/material'
import './Filter.css'
import axios from 'axios'
import { toast } from 'react-toastify'
import { getCookie } from '../../../utils'
import store from '../../../Store'
import { getYMD } from '../../../utils/dateHelpers'
import AdvanceSearch from './AdvanceSearch'
import QuerySearch from './QuerySearch'
import { Autocomplete, TextField, createFilterOptions } from '@mui/material'
import SavedQueriesModal from './SavedQueriesModal'
import Select, { components } from 'react-select'
import { AiOutlineSave } from 'react-icons/ai'
import { IoIosClose, IoIosRefresh } from 'react-icons/io'
import { BiSave, BiSearch } from 'react-icons/bi'
import './Filter.css'
import NoResults from '../../../components/NoResults/NoResults'
import ResultHeader from './ResultsHeader'
import SearchQueryView from '../../../components/NewsEditedCard/SearchQueryView'
import InfiniteScroll from 'react-infinite-scroller'
import { Circles } from 'react-loader-spinner'
import { Grid } from '@mui/material'
import RegionHiearchyMultiSelect from './RegionHiearchyMultiSelect'
import { FaSearch } from 'react-icons/fa'
import NewSearchQueryResult from '../../../components/NewsEditedCard/NewSearchQueryResult'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import FilterListIcon from '@mui/icons-material/FilterList'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import SendIcon from '@mui/icons-material/Send'
import CustomLoader from './CustomLoader'
import NewsHeaderSection from './NewsHeaderSection'
import NewNoResults from '../../../components/NoResults/NewNoResults'

export default function NewAdvanceSearchTab(props) {
    const {
        selectedQueries,
        setSelectedQueries,
        advanceSearch,
        clearFilters,
        editPage,
        advanceResponse,
        setAdvanceResponse,
        expandedView,
        setExpandedView,
        selectedNewsOnly,
        setSelectedNewsOnly,
        selectedStories,
        allCategories,
        allLinkedInGroups,
        removeFromSelection,
        addToSelection,
        // handleLoadMore,
        usertype,
        isLoading,
        isMyRequest,
        currentPath,
        isCreationMyRequest,
        advanceCurrentDate,
        handleAllSelected,
    } = props

    const { level, setLevel } = props

    const {
        allRegions,
        allSources,
        allSubCategories,
        allCompanies,
        allTopics,
        allIndustries,
        setAllCompanies,
        selectedLevel,
        isScore,
        dateFilter,
        newFilterSource,
        setNewsFilters,
        sortBy,
        setSortBy,
        setSelectedStories,
        setActiveStep,
    } = props

    const { setEditPage } = props
    const { editId, setEditId } = props
    const { editqueryName, setEditQueryName } = props
    const { directRun, setDirectRun, runQuery, setRunQuery } = props
    const { isQueryEditor, setIsQueryEditor } = props
    const { isQueryEditorJson, setIsQueryEditorJson } = props
    const { editorQuery, setEditorQuery } = props
    const { regionHierarchy } = props
    const {
        selectedSection,
        setSelectedSection,
        selectedUsers,
        setSelectedUsers,
        emailSubject,
        setEmailSubject,
        includeBusiness,
        setIncludeBusiness,
        includeSection,
        setIncludeSection,
    } = props

    const {
        allCollectionName,
        collectionName,
        setCollectionName,
        collectionDescription,
        setCollectionDescription,
        collectionUsers,
        setCollectionUsers,
        allCollectionUsers,
        collectionShared,
        setCollectionShared,
        saveModal,
        setSaveModal,
        collectionPrivacy,
        setCollectionPrivacy,
        collectionShareList,
        // collectionModal,
        // setCollectionModal,
        collectionId,
        getAllCollections,
        combinedRegCouPro,
        firstSearch,
    } = props
    const { preeditPage, setPreeditPage, basicSearchResponse } = props
    const { getAllCompanies } = props

    const { isAllSelected, setAllSelected } = props
    const { selectedArticles, setSelectedArticles } = props
    const { progressContent } = props
    // const [isLoading, setIsLoading] = useState(false)

    // const [saveModal, setSaveModal] = useState(false)
    const [saveQueryName, setSaveQueryName] = useState('')
    const [collectionModal, setCollectionModal] = useState(false)
    // const [selectedArticles, setSelectedArticles] = useState(true)
    const [advanceSearchOpen, setAdvanceSearchOpen] = useState(false)

    // const [isAllSelected, setAllSelected] = useState(false)
    const [runEdit, setRunEdit] = useState(false)

    // const [isLoading, setIsLoading] = useState(false)

    // useEffect(() => {
    //     if (currentPath === '/app/search/') {
    //         isAllSelected
    //             ? setSelectedStories(current => [
    //                   ...current,
    //                   ...advanceResponse.data.data.filter(each => !selectedStories.includes(each)),
    //               ])
    //             : setSelectedStories([])
    //     }
    // }, [isAllSelected])

    const saveQuerySearch = () => {
        const payLoad = {
            filters: {
                source: {
                    operator: selectedQueries.sourceOperator,
                    values: selectedQueries.source.map(val => val.value),
                },
                articleDate: {
                    operator: selectedQueries.articleOperator,
                    from: selectedQueries.articleDateFrom,
                    to: selectedQueries.articleDateTo,
                },
                countries: {
                    operator: selectedQueries.regionOperator,
                    values: selectedQueries.region.map(val => val.label),
                },
                sectors: {
                    operator: selectedQueries.industryOperator,
                    values: selectedQueries.sectors.map(val => val.label),
                },
                company: {
                    operator: selectedQueries.companyOperator,
                    values: selectedQueries.company.map(val => val.label),
                },
                category: {
                    operator: selectedQueries.categoryOperator,
                    values: selectedQueries.category.map(val => val.label),
                },
                topics: {
                    operator: selectedQueries.topicOperator,
                    values: selectedQueries.topic.map(val => val.label),
                },
                newsTitle: {
                    operator: selectedQueries.newsTitleOperator,
                    value: selectedQueries.newsTitle,
                    fuzziness: 3,
                },
                newsContent: {
                    operator: selectedQueries.newsContentOperator,
                    regex: '*' + selectedQueries.newsContent + '*',
                },
            },
            search_type: 'advanced',
            next: 0, // For Next Batch
            size: 15, // Batch size
        }
        const collectionPayload = {
            name: collectionName,
            description: collectionDescription,
            shared: collectionPrivacy && collectionPrivacy.value,
            users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
        }

        if (editPage === false) {
            const saveQuery = {
                query_name: editPage ? editqueryName : saveQueryName,
                // query: payLoad,
                query_string: isQueryEditorJson,
                section: '',
                subject: emailSubject,
                has_section: includeSection,
                include_subcategory: includeBusiness,
                curation_level: 'level2',
                collection_name: collectionName && collectionName.label ? collectionName.label : collectionName,
                collection_description: collectionDescription,
                collection_privacy: collectionPrivacy && collectionPrivacy.value,
                collection_users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
            }
            const updateSaveQuery = () => {
                axios
                    .post('/news/search-query/', saveQuery)
                    .then(res => {
                        setSaveModal(!saveModal)
                        toast('Query has been successfully saved')
                        clearFilters()
                        setSaveQueryName('')
                        setIsQueryEditorJson('')
                        setSelectedSection('')
                        setSaveModal(false)
                        setSelectedUsers([])
                        setEmailSubject('')
                        setIncludeBusiness(false)
                        setIncludeSection(false)
                        setSaveModal(false)
                        setCollectionModal(false)
                        getAllCollections()
                    })
                    .catch(err => console.log(err))
            }
            if (saveQueryName !== '') {
                if (collectionModal) {
                    axios
                        .post('/news/collection/', collectionPayload)
                        .then(res => {
                            if (res.data && res.data.id) {
                                saveQuery.collection = res.data.id
                                updateSaveQuery()
                            }
                        })
                        .catch(err => console.log(err))
                } else {
                    saveQuery.collection = collectionName && collectionName.value ? collectionName.value : collectionId
                    updateSaveQuery()
                }
            }
        }

        if (editPage === true) {
            const saveEditQuery = {
                // query: editorQuery,
                query_name: editqueryName,
                query_string: isQueryEditorJson,
                section: selectedSection === null ? '' : selectedSection,
                recipients: selectedUsers.length === 0 ? [] : selectedUsers.map(val => val.email),
                subject: emailSubject,
                has_section: includeSection,
                include_subcategory: includeBusiness,
                curation_level: 'level2',
                collection_name: collectionName && collectionName.label ? collectionName.label : collectionName,
                collection_description: collectionDescription,
                collection_privacy: collectionPrivacy && collectionPrivacy.value,
                collection_users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
                collection: collectionName && collectionName.value ? collectionName.value : collectionId,
            }

            const updateSaveEditQuery = () => {
                axios
                    .put(`/news/search-query/${editId}/`, saveEditQuery)
                    .then(res => {
                        toast('Query has been successfully updated')
                        setAdvanceResponse({ data: [], next: null })
                        setEditPage(false)
                        setIsQueryEditorJson('')
                        setSelectedSection('')
                        setSelectedUsers([])
                        setEmailSubject('')
                        setIncludeBusiness(false)
                        setIncludeSection(false)
                        setSaveModal(false)
                        setCollectionModal(false)
                        getAllCollections()
                    })
                    .catch(err => console.log(err))
            }
            if (collectionModal) {
                axios.post('/news/collection/', collectionPayload).then(res => {
                    if (res.data && res.data.id) {
                        saveEditQuery.collection = res.data.id
                        updateSaveEditQuery()
                    }
                })
            } else {
                updateSaveEditQuery()
            }
        }
    }

    const generateQuery = () => {
        const payLoad = {
            filters: {
                source: {
                    operator: selectedQueries.sourceOperator,
                    values: selectedQueries.source.map(val => val.value),
                },
                articleDate: {
                    operator: selectedQueries.articleOperator,
                    from: selectedQueries.articleDateFrom !== '' ? selectedQueries.articleDateFrom : getYMD(startDate),
                    to: selectedQueries.articleDateTo !== '' ? selectedQueries.articleDateTo : getYMD(new Date()),
                },
                countries: {
                    operator: selectedQueries.regionOperator,
                    values: selectedQueries.region.map(val => val.label),
                },
                sectors: {
                    operator: selectedQueries.industryOperator,
                    values: selectedQueries.sectors.map(val => val.label),
                },
                company: {
                    operator: selectedQueries.companyOperator,
                    values: selectedQueries.company.map(val => val.label),
                },
                category: {
                    operator: selectedQueries.categoryOperator,
                    values: selectedQueries.category.map(val => val.label),
                },
                topics: {
                    operator: selectedQueries.topicOperator,
                    values: selectedQueries.topic.map(val => val.label),
                },
                newsTitle: {
                    operator: selectedQueries.newsTitleOperator,
                    value: selectedQueries.newsTitle,
                    fuzziness: 3,
                },
                newsContent: {
                    operator: selectedQueries.newsContentOperator,
                    regex: selectedQueries.newsContent
                        ? selectedQueries.newsContent + '*'
                        : selectedQueries.newsContent,
                },
            },
            search_type: 'advanced',
            next: 0, // For Next Batch
            size: 15, // Batch size
        }

        const saveAdvanceSearchQuery = {
            query_name: directRun ? editqueryName : saveQueryName,
            query: payLoad,
            section: selectedSection === null ? '' : selectedSection,
            recipients: selectedUsers.length === 0 ? '' : selectedUsers.map(val => val.email),
            subject: emailSubject,
            has_section: includeSection,
            include_subcategory: includeBusiness,
            curation_level: 'level2',
            collection_name: collectionName && collectionName.label ? collectionName.label : collectionName,
            collection_description: collectionDescription,
            collection_privacy: collectionPrivacy && collectionPrivacy.value,
            collection_users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
        }
        const collectionPayload = {
            name: collectionName,
            description: collectionDescription,
            shared: collectionPrivacy && collectionPrivacy.value,
            users: collectionUsers.length > 0 ? collectionUsers.map(v => v.id) : [],
        }
        const SaveAdvanceQuery = () => {
            axios
                .post('/news/search-query/', saveAdvanceSearchQuery)
                .then(res => {
                    toast('Query has been successfully saved')
                    clearFilters()
                    setSaveQueryName('')
                    setSelectedSection('')
                    setSelectedUsers([])
                    setEmailSubject('')
                    setIncludeBusiness(false)
                    setIncludeSection(false)
                    setSaveModal(false)
                    setCollectionModal(false)
                    getAllCollections()
                })
                .catch(err => console.log(err))
        }
        if (directRun === false) {
            if (
                (saveQueryName !== '' || editqueryName !== '') &&
                (selectedQueries.articleDateFrom !== '' ||
                    selectedQueries.articleDateTo !== '' ||
                    selectedQueries.region.length !== 0 ||
                    selectedQueries.company.length !== 0 ||
                    selectedQueries.topic.length !== 0 ||
                    selectedQueries.sectors.length !== 0 ||
                    selectedQueries.category.length !== 0 ||
                    selectedQueries.newsTitle !== '' ||
                    selectedQueries.newsContent !== '' ||
                    selectedQueries.source.length !== 0)
            ) {
                if (collectionModal) {
                    axios
                        .post('/news/collection/', collectionPayload)
                        .then(res => {
                            if (res.data && res.data.id) {
                                saveAdvanceSearchQuery.collection = res.data.id
                                SaveAdvanceQuery()
                            }
                        })
                        .catch(err => console.log(err))
                } else {
                    saveAdvanceSearchQuery.collection =
                        collectionName && collectionName.value ? collectionName.value : null
                    SaveAdvanceQuery()
                }
            } else {
                toast.warn('Please fill out all the fields.')
            }
        }

        // edit saved query

        const updateAdvanceQuery = () => {
            axios
                .put(`/news/search-query/${editId}/`, saveAdvanceSearchQuery)
                .then(res => {
                    setSaveModal(false)
                    toast('Query has been successfully updated')
                    setEditId('')
                    setSaveQueryName('')
                    setEditPage(false)
                    clearFilters()
                    setCollectionModal(false)
                    getAllCollections()
                })
                .catch(err => console.log(err))
        }
        if (directRun === true) {
            if (
                selectedQueries.articleDateFrom !== '' ||
                selectedQueries.articleDateTo !== '' ||
                selectedQueries.region.length !== 0 ||
                selectedQueries.company.length !== 0 ||
                selectedQueries.topic.length !== 0 ||
                selectedQueries.sectors.length !== 0 ||
                selectedQueries.category.length !== 0 ||
                selectedQueries.newsTitle !== '' ||
                selectedQueries.newsContent !== '' ||
                selectedQueries.source.length !== 0
            ) {
                if (collectionModal) {
                    axios
                        .post('/news/collection/', collectionPayload)
                        .then(res => {
                            if (res.data && res.data.id) {
                                saveAdvanceSearchQuery.collection = res.data.id
                                updateAdvanceQuery()
                            }
                        })
                        .catch(err => console.log(err))
                } else {
                    saveAdvanceSearchQuery.collection =
                        collectionName && collectionName.value ? collectionName.value : null
                    updateAdvanceQuery()
                }
            } else {
                toast.warn('Please fill out all the fields.')
            }
        }
    }

    var startDate = new Date()
    startDate.setDate(startDate.getDate() - 30)

    const handleLoadMore = () => {
        let data = {
            next: advanceResponse.data.next,
            size: advanceResponse.data.size,
            total: advanceResponse.data.total,
            scroll_id: advanceResponse.data.scroll_id,
        }
        axios.post('/news/scroll/', data).then(response => {
            let newsBefore = [...advanceResponse.data.data, ...response.data.data]
            setAdvanceResponse({
                ...response,
                data: {
                    total: response.data.total,
                    next: response.data.next,
                    scroll_id: response.data.scroll_id,
                    data: newsBefore,
                },
            })
        })
    }

    const filterOption = ({ label, alias }, inputValue) => {
        return (
            label.toLowerCase().includes(inputValue.toLowerCase()) ||
            (alias && alias.toLowerCase().includes(inputValue.toLowerCase()))
        )
    }

    const CustomOption = props => {
        return (
            <components.Option {...props}>
                <div
                    style={{
                        marginLeft:
                            props.data.type === 'country' ? '10px' : props.data.type === 'province' ? '20px' : '',
                    }}
                >
                    {props.children}
                </div>
            </components.Option>
        )
    }

    return (
        <>
            <div
                style={{
                    padding: isMyRequest || isCreationMyRequest ? '10px 10px 10px 10px' : '40px 10px 10px 10px',
                    marginTop: '-2px',
                    width: '100%',
                }}
                className="drop_down_sections_new row"
            >
                <div className="col">
                    {!runEdit && (
                        <>
                            <div className="sections">
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    id="region-section"
                                >
                                    <label className="filter_label_new">Article Date</label>
                                    <span className="filter_span_new" style={{ fontSize: '12px' }}>
                                        <Input
                                            onChange={e => {
                                                setSelectedQueries({
                                                    ...selectedQueries,
                                                    articleOperator: e.target.value,
                                                })
                                            }}
                                            value={selectedQueries.articleOperator}
                                            type="select"
                                            style={{
                                                height: '37px',
                                                width: '110px',
                                                fontSize: '12px',
                                                // marginTop: '-6px',
                                                fontWeight: '600',
                                                borderRadius: '4px',
                                                color: 'hsl(0,0%,50%)',
                                            }}
                                        >
                                            <option value="BETWEEN">is between</option>
                                            <option value="NOT_BETWEEN">not between</option>
                                        </Input>
                                    </span>

                                    <input
                                        className="inputs_date_new"
                                        type="date"
                                        value={
                                            selectedQueries.articleDateFrom == ''
                                                ? getYMD(startDate)
                                                : selectedQueries.articleDateFrom
                                        }
                                        onChange={e => {
                                            setSelectedQueries({
                                                ...selectedQueries,
                                                articleDateFrom: e.target.value,
                                            })
                                        }}
                                        style={{ fontSize: '13px' }}
                                    />
                                    <span
                                        className=""
                                        style={{
                                            fontSize: '13px',
                                            fontWeight: 600,
                                            textAlign: 'center',
                                            width: '50px',
                                            // marginTop: '10px',
                                            // marginLeft: '-10px',
                                        }}
                                    >
                                        and
                                    </span>
                                    <input
                                        className="inputs_date_new2"
                                        type="date"
                                        value={
                                            selectedQueries.articleDateTo == ''
                                                ? getYMD(new Date())
                                                : selectedQueries.articleDateTo
                                        }
                                        onChange={e => {
                                            setSelectedQueries({
                                                ...selectedQueries,
                                                articleDateTo: e.target.value,
                                            })
                                        }}
                                        style={{ fontSize: '13px' }}
                                    />
                                </div>
                            </div>
                            <div className="sections">
                                <div
                                    style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}
                                    id="region-section"
                                >
                                    <label className="filter_label_new">Region/Country/Province</label>
                                    <div className="filter_span_new" style={{ color: 'gray' }}>
                                        <Input
                                            onChange={e => {
                                                setSelectedQueries({
                                                    ...selectedQueries,
                                                    regionOperator: e.target.value,
                                                })
                                            }}
                                            value={selectedQueries.regionOperator}
                                            type="select"
                                            style={{
                                                height: '37px',
                                                width: '110px',
                                                fontSize: '12px',
                                                // marginTop: '-6px',
                                                fontWeight: '600',
                                                borderRadius: '4px',
                                                color: 'hsl(0,0%,50%)',
                                            }}
                                        >
                                            <option value="IS">is</option>
                                            <option value="IS_NOT">is not</option>
                                        </Input>
                                    </div>
                                    {/* <Select
                                        isMulti
                                        name="region"
                                        options={allRegions.sort((a, b) => (a.label > b.label ? 1 : -1))}
                                        onChange={value => {
                                            setSelectedQueries({
                                                ...selectedQueries,
                                                region: value === null ? [] : value,
                                            })
                                        }}
                                        value={selectedQueries.region}
                                        placeholder="Select Country"
                                        className="inputs_new"
                                    /> */}
                                    <Select
                                        isMulti
                                        name="region"
                                        options={combinedRegCouPro.sort((a, b) => (a.label > b.label ? 1 : -1))}
                                        onChange={value => {
                                            setSelectedQueries({
                                                ...selectedQueries,
                                                region: value === null ? [] : value,
                                            })
                                        }}
                                        value={selectedQueries.region}
                                        placeholder="Select Region/Country/Province"
                                        className="inputs_new"
                                        components={{ Option: CustomOption }}
                                        // formatOptionLabel={(option, { context }) =>
                                        //     context === 'menu' ? `${option.label}(${option.type})` : option.label
                                        // }
                                    />
                                </div>
                            </div>
                            <div className="sections">
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: '10px',
                                    }}
                                    id="company-section"
                                >
                                    <label className="filter_label_new">Company</label>
                                    <span className="filter_span_new" style={{ color: 'gray' }}>
                                        <Input
                                            onChange={e => {
                                                setSelectedQueries({
                                                    ...selectedQueries,
                                                    companyOperator: e.target.value,
                                                })
                                            }}
                                            value={selectedQueries.companyOperator}
                                            type="select"
                                            placeholder=""
                                            style={{
                                                height: '37px',
                                                width: '110px',
                                                fontSize: '12px',
                                                // marginTop: '-6px',
                                                fontWeight: '600',
                                                borderRadius: '4px',
                                                color: 'hsl(0,0%,50%)',
                                            }}
                                        >
                                            <option value="IS">is</option>
                                            <option value="ISNOT">is not</option>
                                        </Input>
                                    </span>
                                    <Select
                                        isMulti
                                        className="inputs_new"
                                        value={selectedQueries.company}
                                        placeholder="Select Company"
                                        options={allCompanies.sort((a, b) => (a.label > b.label ? 1 : -1))}
                                        onChange={value => {
                                            setSelectedQueries({
                                                ...selectedQueries,
                                                company: value === null ? [] : value,
                                            })
                                        }}
                                        filterOption={(option, inputValue) => filterOption(option.data, inputValue)}
                                        onInputChange={e => {
                                            if (e.length > 2) {
                                                axios
                                                    .get(
                                                        `/news/auto-complete/?type=company&string=${encodeURIComponent(
                                                            e
                                                        )}`
                                                    )
                                                    .then(res => {
                                                        const filteredData = res.data.map(item => ({
                                                            label: item.name,
                                                            value: item.id,
                                                            alias: item.alias,
                                                        }))
                                                        setAllCompanies(filteredData)
                                                    })
                                                    .catch(err => console.log(err))
                                            } else {
                                                setAllCompanies(allCompanies)
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="sections">
                                <div
                                    style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}
                                    id="topic-section"
                                >
                                    <label className="filter_label_new">Topic</label>
                                    <span className="filter_span_new" style={{ color: 'gray' }}>
                                        <Input
                                            onChange={e => {
                                                setSelectedQueries({
                                                    ...selectedQueries,
                                                    topicOperator: e.target.value,
                                                })
                                            }}
                                            value={selectedQueries.topicOperator}
                                            type="select"
                                            style={{
                                                height: '37px',
                                                width: '110px',
                                                fontSize: '12px',
                                                // marginTop: '-6px',
                                                fontWeight: '600',
                                                borderRadius: '4px',
                                                color: 'hsl(0,0%,50%)',
                                            }}
                                        >
                                            <option value="IS">is</option>
                                            <option value="IS_NOT">is not</option>
                                        </Input>
                                    </span>
                                    <Select
                                        isMulti
                                        className="inputs_new"
                                        value={selectedQueries.topic}
                                        placeholder="Select Topic"
                                        options={allTopics.sort((a, b) => (a.label > b.label ? 1 : -1))}
                                        onChange={value => {
                                            setSelectedQueries({
                                                ...selectedQueries,
                                                topic: value === null ? [] : value,
                                            })
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="sections">
                                <div
                                    style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}
                                    id="industry-section"
                                >
                                    <label className="filter_label_new">Industry/Sector</label>
                                    <span className="filter_span_new" style={{ color: 'gray' }}>
                                        <Input
                                            value={selectedQueries.industryOperator}
                                            onChange={e => {
                                                setSelectedQueries({
                                                    ...selectedQueries,
                                                    industryOperator: e.target.value,
                                                })
                                            }}
                                            type="select"
                                            style={{
                                                height: '37px',
                                                width: '110px',
                                                fontSize: '12px',
                                                // marginTop: '-6px',
                                                fontWeight: '600',
                                                borderRadius: '4px',
                                                color: 'hsl(0,0%,50%)',
                                            }}
                                        >
                                            <option value="IS">is</option>
                                            <option value="IS_NOT">is not</option>
                                        </Input>
                                    </span>
                                    <Select
                                        isMulti
                                        className="inputs_new"
                                        value={selectedQueries.sectors}
                                        placeholder="Select Industry/Sector"
                                        options={allIndustries.sort((a, b) => (a.label > b.label ? 1 : -1))}
                                        onChange={value => {
                                            setSelectedQueries({
                                                ...selectedQueries,
                                                sectors: value === null ? [] : value,
                                            })
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="sections">
                                <div
                                    style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}
                                    id="category-section"
                                >
                                    <label className="filter_label_new">Business Event(s)</label>
                                    <span className="filter_span_new" style={{ color: 'gray' }}>
                                        <Input
                                            value={selectedQueries.categoryOperator}
                                            onChange={e => {
                                                setSelectedQueries({
                                                    ...selectedQueries,
                                                    categoryOperator: e.target.value,
                                                })
                                            }}
                                            type="select"
                                            style={{
                                                height: '37px',
                                                width: '110px',
                                                fontSize: '12px',
                                                // marginTop: '-6px',
                                                fontWeight: '600',
                                                borderRadius: '4px',
                                                color: 'hsl(0,0%,50%)',
                                            }}
                                        >
                                            <option value="IS">is</option>
                                            <option value="IS_NOT">is not</option>
                                        </Input>
                                    </span>

                                    <Select
                                        isMulti
                                        className="inputs_new"
                                        value={selectedQueries.category}
                                        placeholder="Select Business Event(s)"
                                        options={allSubCategories.sort((a, b) => (a.label > b.label ? 1 : -1))}
                                        onChange={value => {
                                            setSelectedQueries({
                                                ...selectedQueries,
                                                category: value === null ? [] : value,
                                            })
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="sections">
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: '15px',
                                    }}
                                    id="newstitle-section"
                                >
                                    <label className="filter_label_new">News Title</label>
                                    <span className="filter_span_new" style={{ color: 'gray' }}>
                                        <Input
                                            value={selectedQueries.newsTitleOperator}
                                            onChange={e => {
                                                setSelectedQueries({
                                                    ...selectedQueries,
                                                    newsTitleOperator: e.target.value,
                                                })
                                            }}
                                            type="select"
                                            style={{
                                                height: '37px',
                                                width: '110px',
                                                fontSize: '12px',
                                                // marginTop: '-6px',
                                                fontWeight: '600',
                                                borderRadius: '4px',
                                                color: 'hsl(0,0%,50%)',
                                            }}
                                        >
                                            <option value="CONTAINS">contains</option>
                                            <option value="CONTAINS_NOT">doesn't contains</option>
                                        </Input>
                                    </span>
                                    <input
                                        className="inputs_text"
                                        type="text"
                                        value={selectedQueries.newsTitle}
                                        placeholder="Enter News Title"
                                        onChange={e => {
                                            setSelectedQueries({ ...selectedQueries, newsTitle: e.target.value })
                                        }}
                                        style={{ fontSize: '13px' }}
                                    />
                                </div>
                            </div>

                            <div className="sections">
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: '15px',
                                    }}
                                    id="newscontent-section"
                                >
                                    <label className="filter_label_new">News Content</label>
                                    <span className="filter_span_new" style={{ color: 'gray' }}>
                                        <Input
                                            value={selectedQueries.newsContentOperator}
                                            onChange={e => {
                                                setSelectedQueries({
                                                    ...selectedQueries,
                                                    newsContentOperator: e.target.value,
                                                })
                                            }}
                                            type="select"
                                            style={{
                                                height: '37px',
                                                width: '110px',
                                                fontSize: '12px',
                                                // marginTop: '-6px',
                                                fontWeight: '600',
                                                borderRadius: '4px',
                                                color: 'hsl(0,0%,50%)',
                                            }}
                                        >
                                            <option value="CONTAINS">contains</option>
                                            <option value="CONTAINS_NOT">doesn't contains</option>
                                        </Input>
                                    </span>
                                    <input
                                        value={selectedQueries.newsContent}
                                        className="inputs_text"
                                        type="text"
                                        placeholder="Enter News Content"
                                        onChange={e =>
                                            setSelectedQueries({ ...selectedQueries, newsContent: e.target.value })
                                        }
                                        style={{ fontSize: '13px' }}
                                    />
                                    {/* <AiOutlineCloseCircle onClick={() => removeVal('newscontent', 4)} className="close_button" /> */}
                                </div>
                            </div>

                            <div className="sections">
                                <div
                                    style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}
                                    id="source-section"
                                >
                                    <label className="filter_label_new">Source</label>
                                    <span className="filter_span_new" style={{ color: 'gray' }}>
                                        <Input
                                            value={selectedQueries.sourceOperator}
                                            onChange={e => {
                                                setSelectedQueries({
                                                    ...selectedQueries,
                                                    sourceOperator: e.target.value,
                                                })
                                            }}
                                            type="select"
                                            style={{
                                                height: '37px',
                                                width: '110px',
                                                fontSize: '12px',
                                                // marginTop: '-5px',
                                                fontWeight: '600',
                                                borderRadius: '4px',
                                                color: 'hsl(0,0%,50%)',
                                                // marginTop: '2px',
                                            }}
                                        >
                                            <option value="is">is</option>
                                            <option value="isnot">is not</option>
                                        </Input>
                                    </span>
                                    <Select
                                        isMulti
                                        name="source"
                                        placeholder="Select Source"
                                        options={allSources.sort((a, b) => (a.label > b.label ? 1 : -1))}
                                        onChange={value => {
                                            setSelectedQueries({
                                                ...selectedQueries,
                                                source: value === null ? [] : value,
                                            })
                                        }}
                                        value={selectedQueries.source}
                                        className="inputs_new"
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    <div className="btn_space d-flex sections">
                        {runEdit && !isMyRequest && !isCreationMyRequest && (
                            <Button
                                variant="contained"
                                endIcon={<BiSave style={{ fontSize: '18px' }} />}
                                style={{
                                    background: '#186ADE',
                                    borderRadius: '4px',
                                    width: 'max-content',
                                    // marginLeft: '20px',
                                    fontSize: '14px',
                                    height: '40px',
                                    color: 'white',
                                    boxShadow: 'none',
                                    textTransform: 'none',
                                }}
                                onClick={() => {
                                    setSaveModal(true)
                                }}
                            >
                                Save Search
                            </Button>
                        )}

                        {(isMyRequest || isCreationMyRequest) && (
                            <Button
                                variant="contained"
                                style={{
                                    background: '#186ADE',
                                    borderRadius: '4px',
                                    width: 'max-content',
                                    fontSize: '14px',
                                    height: '40px',
                                    color: 'white',
                                    textTransform: 'none',
                                }}
                                onClick={() => {
                                    advanceSearch()
                                    setRunEdit(!runEdit)
                                }}
                                // onClick={advanceSearch}
                            >
                                Run Search
                            </Button>
                        )}

                        {!runEdit && !isMyRequest && !isCreationMyRequest ? (
                            <Button
                                variant="contained"
                                style={{
                                    background: '#186ADE',
                                    borderRadius: '4px',
                                    width: 'max-content',
                                    fontSize: '14px',
                                    height: '40px',
                                    color: 'white',
                                    textTransform: 'none',
                                }}
                                onClick={() => {
                                    advanceSearch()
                                    setRunEdit(!runEdit)
                                }}
                                // onClick={advanceSearch}
                            >
                                Run Search
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                style={{
                                    background: 'rgba(207, 207, 207, 0)',
                                    borderRadius: '4px',
                                    width: 'max-content',
                                    fontSize: '14px',
                                    height: '40px',
                                    color: 'black',
                                    textTransform: 'none',
                                    textDecoration: 'underline',
                                    boxShadow: 'none',
                                    marginLeft: '60px',
                                }}
                                onClick={() => {
                                    // advanceSearch()
                                    setRunEdit(!runEdit)
                                }}
                            >
                                Edit Search
                            </Button>
                        )}

                        {!runEdit && !isMyRequest && !isCreationMyRequest && (
                            <Button
                                variant="contained"
                                style={{
                                    background: 'rgba(207, 207, 207, 0)',
                                    borderRadius: '4px',
                                    width: 'max-content',
                                    marginLeft: '60px',
                                    fontSize: '14px',
                                    height: '40px',
                                    color: '#1C2B36',
                                    textTransform: 'none',
                                    textDecoration: 'underline',
                                    boxShadow: 'none',
                                }}
                                onClick={e => clearFilters(e)}
                            >
                                Reset
                            </Button>
                        )}

                        {!isMyRequest && !isCreationMyRequest && (
                            <Button
                                variant="contained"
                                endIcon={<SendIcon style={{ fontSize: '18px' }} />}
                                style={{
                                    background: '#186ADE',
                                    borderRadius: '4px',
                                    width: 'max-content',
                                    // marginRight: '20px',
                                    fontSize: '14px',
                                    height: '40px',
                                    color: 'white',
                                    opacity: selectedStories.length > 0 ? 1 : 0.6,
                                    textTransform: 'none',
                                    marginLeft: runEdit ? '40px' : '80px',
                                }}
                                disabled={selectedStories.length > 0 ? false : true}
                                onClick={() => {
                                    setActiveStep(1)
                                }}
                            >
                                Send Newsletter
                            </Button>
                        )}
                    </div>
                    <div style={{ padding: '10px 20px' }}>
                        {advanceResponse &&
                        advanceResponse.data &&
                        advanceResponse.data.data &&
                        (advanceResponse.data.data.length > 0 ||
                            advanceResponse.data.data.filter(
                                val =>
                                    (val.title && val.title.length > 0) || (val.title && val.title !== '') || !val.title
                            ).length > 0) ? (
                            <div style={{ width: '98%', margin: 'auto' }}>
                                {/* <div
                                    className="d-flex align-items-center justify-content-between m-auto"
                                    style={{ width: '97%' }}
                                >
                                    <div />
                                    <div style={{ fontSize: '14px' }}>
                                        Showing 1 - {advanceResponse.data.data.length} of {advanceResponse.data.total}{' '}
                                        results for last {advanceCurrentDate} days
                                    </div>
                                    <select style={{ outline: 'none', borderRadius: '4px', background: 0 }} disabled>
                                        <option value={null} style={{ borderRadius: '0px' }}>
                                            Date
                                        </option>
                                        <option value="relevance">Relevance</option>
                                    </select>
                                </div>
                                <div style={{ width: '98%', margin: 'auto', marginTop: '15px' }}>
                                    <div
                                        style={{
                                            background: '#00193C',
                                            color: 'white',
                                            padding: '10px',
                                            borderRadius: '4px 4px 0px 0px',
                                            marginTop: '10px',
                                        }}
                                        className="d-flex align-items-center justify-content-between"
                                    >
                                        <div style={{ width: '52%' }}>
                                            {!isMyRequest && !isCreationMyRequest && (
                                                <span
                                                    style={{ marginLeft: '1%' }}
                                                    onClick={() => setAllSelected(!isAllSelected)}
                                                >
                                                    {!isAllSelected ? (
                                                        <CheckBoxOutlineBlankIcon style={{ fontSize: 'larger' }} />
                                                    ) : (
                                                        <CheckBoxIcon style={{ fontSize: 'larger' }} />
                                                    )}
                                                </span>
                                            )}

                                            <span
                                                style={{
                                                    marginLeft: !isMyRequest && !isCreationMyRequest ? '5%' : '3%',
                                                }}
                                            >
                                                Title
                                            </span>
                                        </div>
                                        {advanceResponse.data[0].score && <div style={{ width: '10%' }}>Relevance</div>}
                                        <div
                                            style={{
                                                width: '15%',
                                                // marginLeft: advanceResponse.data[0].score ? '2%' : '',
                                                marginLeft: '2%',
                                            }}
                                        >
                                            Source
                                        </div>
                                        <div style={{ width: '15%', marginRight: '1%' }}>Date</div>
                                        <div />
                                    </div>
                                </div> */}
                                <NewsHeaderSection
                                    newsData={advanceResponse.data}
                                    currentDate={advanceCurrentDate}
                                    isAdvanceSearch={true}
                                    isAllSelected={isAllSelected}
                                    setAllSelected={setAllSelected}
                                    isMyRequest={isMyRequest}
                                    isCreationMyRequest={isCreationMyRequest}
                                    handleAllSelected={handleAllSelected}
                                />
                                <Grid item style={{ width: '98%', margin: 'auto' }}>
                                    <>
                                        <InfiniteScroll
                                            pageStart={0}
                                            loadMore={handleLoadMore}
                                            hasMore={advanceResponse.data.next < advanceResponse.data.total}
                                            loader={
                                                <div className="d-flex align-items-center justify-content-center mt-3">
                                                    <CustomLoader />
                                                </div>
                                            }
                                        >
                                            {advanceResponse.data.data
                                                // .filter(story => {
                                                //     const startDate = new Date()
                                                //     startDate.setDate(startDate.getDate() - dateFilter)

                                                //     const filterDate = getYMD(startDate)
                                                //     const storyDate = story.date.split('T')[0]
                                                //     const dateCondition = storyDate > filterDate

                                                //     const isMultipleSources = newFilterSource.length > 0

                                                //     const sourceCondition = isMultipleSources
                                                //         ? newFilterSource.includes(story.source_name)
                                                //         : true

                                                //     return dateCondition && sourceCondition
                                                // })
                                                .sort((a, b) =>
                                                    sortBy === 'date'
                                                        ? a.date.split('T')[0] > b.date.split('T')[0]
                                                            ? -1
                                                            : 1
                                                        : sortBy === 'relevance'
                                                        ? a.score > b.score
                                                            ? -1
                                                            : 1
                                                        : 0
                                                )
                                                .map(story => {
                                                    return (
                                                        <NewSearchQueryResult
                                                            isFindAndShare={true}
                                                            key={story.id}
                                                            EachrawNews={story}
                                                            isBasicSearch={true}
                                                            selectedStories={selectedStories}
                                                            isStorySelected={
                                                                selectedStories.findIndex(
                                                                    arr => arr.id === story.id
                                                                ) === -1
                                                                    ? false
                                                                    : true
                                                            }
                                                            addToSelection={addToSelection}
                                                            allCategories={allCategories}
                                                            allSubCategories={allSubCategories}
                                                            allIndustries={allIndustries}
                                                            allCompanies={allCompanies}
                                                            setAllCompanies={setAllCompanies}
                                                            allRegions={allRegions}
                                                            allLinkedInGroups={allLinkedInGroups}
                                                            removeFromSelection={removeFromSelection}
                                                            allTopics={allTopics}
                                                            collapsed={!expandedView}
                                                            usertype={usertype}
                                                            selectAll={isAllSelected}
                                                            currentPath={currentPath}
                                                            selectedNewsOnly={selectedNewsOnly}
                                                            isScore={true}
                                                            selectedLevel={selectedLevel}
                                                            isAssisstant={isCreationMyRequest ? true : false}
                                                        />
                                                    )
                                                })}
                                        </InfiniteScroll>
                                    </>
                                </Grid>
                            </div>
                        ) : isLoading ? (
                            <div
                                style={{
                                    marginTop: '10px',
                                }}
                                className="d-flex align-items-center justify-content-center"
                            >
                                <CustomLoader progressContent={progressContent} />
                            </div>
                        ) : (
                            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                                {firstSearch && firstSearch.advance === false ? (
                                    <NewNoResults />
                                ) : (
                                    <>
                                        <FaSearch style={{ fontSize: '100px', color: 'lightgray' }} />
                                        <p style={{ color: 'lightgray' }}>
                                            Start typing relevant keywords to see results
                                        </p>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                {!isMyRequest && !isCreationMyRequest && selectedStories.length > 0 && (
                    <>
                        {!selectedArticles && (
                            <>
                                {/* <div
                                        className="advance_section"
                                        style={{
                                            height:
                                                document.documentElement.scrollHeight > 1500
                                                    ? document.documentElement.scrollHeight
                                                    : '90vh',
                                            background: '#F2F5F7',
                                            transition: 'opacity 3s ease',
                                            overflow: 'auto',
                                            width: '50px',
                                            cursor: 'pointer',
                                        }}
                                    /> */}
                                <div
                                    className="advance_section"
                                    style={{
                                        height:
                                            document.documentElement.scrollHeight > 1500
                                                ? document.documentElement.scrollHeight
                                                : '90vh',
                                        background: '#F2F5F7',
                                        transition: 'opacity 3s ease',
                                        overflow: 'auto',
                                        width: '50px',
                                        cursor: 'pointer',
                                        position: 'fixed',
                                        right: 0,
                                        top: '70px',
                                        zIndex: 8,
                                        boxShadow: '-1px 0 1px -1px rgba(0, 0, 0, 0.5)',
                                    }}
                                >
                                    <KeyboardDoubleArrowLeftIcon
                                        style={{ marginTop: '10px' }}
                                        onClick={() => {
                                            setSelectedArticles(true)
                                        }}
                                    />
                                    <hr style={{ margin: '10px 0px' }} />
                                </div>
                            </>
                        )}

                        {selectedArticles && (
                            <>
                                <div
                                    className="advance_section"
                                    style={{
                                        height:
                                            document.documentElement.scrollHeight > 1500
                                                ? document.documentElement.scrollHeight
                                                : '100vh',
                                        // background: '#F2F5F7',
                                        transition: 'opacity 3s ease',
                                        overflow: 'auto',
                                        width: '260px',
                                        // boxShadow: '-1px 0 1px -1px rgba(0, 0, 0, 0.5)',
                                    }}
                                />
                                <div
                                    className="advance_section"
                                    style={{
                                        height:
                                            document.documentElement.scrollHeight > 1500
                                                ? document.documentElement.scrollHeight
                                                : '90vh',
                                        background: '#F2F5F7',
                                        transition: 'opacity 3s ease',
                                        overflow: 'auto',
                                        width: '260px',
                                        position: 'fixed',
                                        right: 0,
                                        top: '70px',
                                        zIndex: 8,
                                        boxShadow: '-1px 0 1px -1px rgba(0, 0, 0, 0.5)',
                                    }}
                                >
                                    <div>
                                        <div
                                            className="d-flex justify-content-between align-items-center"
                                            style={{ marginTop: '10px', cursor: 'pointer' }}
                                        >
                                            <KeyboardDoubleArrowRightIcon
                                                onClick={() => {
                                                    setSelectedArticles(false)
                                                }}
                                            />

                                            <label style={{ fontSize: '16px' }}>Selected Articles</label>
                                            <span
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setSelectedArticles(false)
                                                }}
                                            >
                                                <IoIosClose style={{ fontSize: '26px' }} />
                                            </span>
                                        </div>
                                        <hr style={{ margin: '10px 0px' }} />
                                        <div>
                                            {selectedStories.map(story => {
                                                return (
                                                    <NewSearchQueryResult
                                                        isFindAndShare={true}
                                                        key={story.id}
                                                        EachrawNews={story}
                                                        isBasicSearch={true}
                                                        selectedStories={selectedStories}
                                                        isStorySelected={
                                                            selectedStories.findIndex(arr => arr.id === story.id) === -1
                                                                ? false
                                                                : true
                                                        }
                                                        addToSelection={addToSelection}
                                                        allCategories={allCategories}
                                                        allSubCategories={allSubCategories}
                                                        allIndustries={allIndustries}
                                                        allCompanies={allCompanies}
                                                        setAllCompanies={setAllCompanies}
                                                        allRegions={allRegions}
                                                        allLinkedInGroups={allLinkedInGroups}
                                                        removeFromSelection={removeFromSelection}
                                                        allTopics={allTopics}
                                                        collapsed={!expandedView}
                                                        usertype={usertype}
                                                        selectAll={isAllSelected}
                                                        currentPath={currentPath}
                                                        selectedNewsOnly={selectedNewsOnly}
                                                        isScore={isScore}
                                                        selectedLevel={selectedLevel}
                                                        selectedOnly={true}
                                                    />
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}

                <Modal
                    isOpen={saveModal}
                    toggle={() => {
                        setSaveModal(!saveModal)
                    }}
                    autoFocus={false}
                >
                    <ModalHeader style={{ width: '100%' }}>
                        <div style={{ marginTop: '10px' }} className="d-flex justify-content-between">
                            <div style={{ marginLeft: '10px' }}> Save Search</div>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <label>Name</label>
                        <br />
                        <TextField
                            value={directRun ? editqueryName : saveQueryName}
                            onChange={e => {
                                directRun ? setEditQueryName(e.target.value) : setSaveQueryName(e.target.value)
                            }}
                            placeholder="Name"
                            variant="outlined"
                            size="small"
                            sx={{ width: '100%' }}
                        />
                        {!collectionModal && (
                            <>
                                <label className="mt-3">Choose a Newsletter</label>
                                <br />
                                <Autocomplete
                                    // sx={{ marginTop: '20px' }}
                                    className="mt-1"
                                    options={allCollectionName}
                                    autoHighlight
                                    value={collectionName}
                                    getOptionLabel={option => option.label}
                                    renderOption={(props, option) => (
                                        <li
                                            {...props}
                                            style={{
                                                color: option.value === 'Add into New Newsletter' ? '#197bbd' : 'black',
                                            }}
                                        >
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            // sx={{ height: '40px' }}
                                            // style={{ height: 40 }}
                                            variant="outlined"
                                            placeholder="Choose a Newsletter"
                                            size="small"
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        if (value !== null && value.value === 'Add into New Newsletter') {
                                            setCollectionModal(true)
                                            // setOpenModal(false)
                                            setCollectionName(null)
                                            // setCollectionName('')
                                        } else {
                                            setCollectionName(value)
                                            setCollectionModal(false)
                                        }
                                    }}
                                />
                            </>
                        )}
                        {collectionModal && (
                            <>
                                <label className="mt-3">Newsletter Name</label>
                                <br />
                                <TextField
                                    value={collectionName}
                                    onChange={e => {
                                        setCollectionName(e.target.value)
                                    }}
                                    placeholder="Newsletter Name"
                                    variant="outlined"
                                    sx={{ width: '100%' }}
                                    size="small"
                                    className="mt-1"
                                />
                                <label className="mt-3">Description</label>
                                <br />
                                <TextField
                                    value={collectionDescription}
                                    onChange={e => {
                                        setCollectionDescription(e.target.value)
                                    }}
                                    placeholder="Description"
                                    variant="outlined"
                                    sx={{ width: '100%' }}
                                    size="small"
                                    className="mt-1"
                                />
                                <label className="mt-3">Shareable</label>
                                <br />
                                <Autocomplete
                                    // sx={{ marginTop: '20px' }}
                                    className="mt-1"
                                    options={collectionShareList}
                                    autoHighlight
                                    value={collectionPrivacy}
                                    getOptionLabel={option => option.label}
                                    // renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            style={{ borderRadius: '10px' }}
                                            variant="outlined"
                                            placeholder="Shareable"
                                            size="small"
                                        />
                                    )}
                                    defaultValue={collectionShareList[0]} // Set your default value here
                                    onChange={(event, value) => {
                                        // setCollectionName(value)
                                        if (value !== null) {
                                            setCollectionPrivacy(value)
                                        }
                                    }}
                                />

                                {collectionPrivacy && collectionPrivacy.value === false && (
                                    <>
                                        <label className="mt-3">Users</label>
                                        <br />
                                        <Autocomplete
                                            multiple
                                            className="mt-1"
                                            // sx={{ marginTop: '20px' }}
                                            options={allCollectionUsers}
                                            autoHighlight
                                            value={collectionUsers}
                                            getOptionLabel={option => option.email}
                                            renderOption={(props, option) => <li {...props}>{option.email}</li>}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    // sx={{ height: '40px' }}
                                                    style={{ borderRadius: '10px' }}
                                                    variant="outlined"
                                                    placeholder="Users"
                                                    size="small"
                                                />
                                            )}
                                            onChange={(event, value) => {
                                                setCollectionUsers(value)
                                            }}
                                        />
                                    </>
                                )}
                            </>
                        )}
                        <div
                            style={{
                                display: 'flex',
                                marginLeft: 'auto',
                                paddingTop: '20px',
                                marginRight: '15px',
                                // marginLeft: '269px',
                            }}
                        >
                            <Button
                                variant="contained"
                                style={{
                                    background: '#186ADE',
                                    borderRadius: '4px',
                                    width: 'max-content',
                                    // marginLeft: '20px',
                                    fontSize: '14px',
                                    height: '40px',
                                    color: 'white',
                                    boxShadow: 'none',
                                    textTransform: 'none',
                                }}
                                className="Cancel_btn"
                                onClick={() => {
                                    setSaveModal(false)
                                    setCollectionModal(false)
                                    setCollectionName(null)
                                }}
                            >
                                Cancel
                            </Button>

                            <Button
                                variant="contained"
                                style={{
                                    background: '#186ADE',
                                    borderRadius: '4px',
                                    width: 'max-content',
                                    // marginLeft: '20px',
                                    fontSize: '14px',
                                    height: '40px',
                                    color: 'white',
                                    boxShadow: 'none',
                                    textTransform: 'none',
                                    marginLeft: '20px',
                                }}
                                onClick={generateQuery}
                                className="save_btn"
                            >
                                Save
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        </>
    )
}
