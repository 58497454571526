import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Circles } from 'react-loader-spinner'
import NewCards from '../../screens/NewsFeedScreen/components/NewCards'

export default function CompanyNewsV2(props) {
    return (
        <div>
            <div className="companyNews__heading">
                <h3
                    className="headingGeneric company__overviewHeading"
                    style={{ fontWeight: '800', fontSize: '21px', marginBottom: '30px' }}
                >
                    News & Key Updates
                </h3>
            </div>
            {props.CompanyNews.data && (
                <InfiniteScroll
                    pageStart={0}
                    loadMore={props.handleLoadMore}
                    hasMore={props.CompanyNews.next !== null}
                    loader={
                        <div style={{ marginTop: '5%', marginLeft: '20px' }}>
                            <Circles key={0} type="Grid" color="#adadad" height={80} width={80} />
                        </div>
                    }
                >
                    <div className="companyNews__storyMap">
                        <div
                            style={{
                                minWidth: '450px',
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr 1fr',
                                gridColumn: '20px',
                                columnGap: '20px',
                                marginTop: '-20PX',
                            }}
                        >
                            {props.CompanyNews.data.map((story, index) => {
                                let hasMargin = false
                                if (index % 3 !== 2 && props.windowWidth > 900) {
                                    hasMargin = true
                                }
                                return <NewCards val={story} index={index} isTopicPage={true} />
                            })}
                        </div>
                    </div>
                </InfiniteScroll>
            )}
        </div>
    )
}
