import React, { useEffect, useRef, useState } from 'react'
import BaseScreen from '../../BaseScreen/BaseScreen'
import axios from 'axios'
import ArrowBackIosNewSharp from '@mui/icons-material/ArrowBackIosNewSharp'
import ArrowForwardIosSharp from '@mui/icons-material/ArrowForwardIosSharp'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import { Circles } from 'react-loader-spinner'
import CompanyNewsV2 from '../../../components/CompanyScreen/CompanyNewsV2'
import Description from './Description'
import Overview from './Overview'
import html2pdf from 'html2pdf.js'
import { getCookie } from '../../../utils'
import PreMeetingBriefModal from '../../NewsFeedScreen/components/PreMeetingBriefModal'
import { getYMD } from '../../../utils/dateHelpers'
import CustomLoader from '../../NewsFeedScreen/components/CustomLoader'
import ConversationStarters from './ConversationStarters'
import { Button } from '@mui/material'
import ConversationModal from './ConversationModal'
import QuickAdvanceFilters from '../../NewsFeedScreen/components/QuickAdvanceFilters'
import { IoIosClose } from 'react-icons/io'
import FilterListIcon from '@mui/icons-material/FilterList'

export default function MyCompanyScreen(props) {
    const filterInputRef = useRef(null)
    const screenType = props.location.pathname === '/app/companies/' ? 'companies' : 'competitors'
    const [allCompanies, setAllCompanies] = useState([])
    const [selectedCompany, setSelectedCompany] = useState()
    const [companyInformation, setCompanyInformation] = useState()
    const [companyNews, setCompanyNews] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [premeetingPreview, setPremeetingPreview] = useState(false)
    const [premeetingData, setPremeetingData] = useState('')
    const [preMeetingLoader, setPreMeetingLoader] = useState(false)
    const [companyName, setCompanyName] = useState({ id: null, name: null })
    const [payloadToken, setPayloadToken] = useState(null)
    const [progressContent, setProgressContent] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const [strategicModal, setStrategicModal] = useState(false)
    const [newsFilters, setNewsFilters] = useState(false)
    const [selectedSectors, setSelectedSectors] = useState([])
    const [selectedCompanies, setSelectedCompanies] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [selectedTopics, setSelectedTopics] = useState([])
    const [selectedRegions, setSelectedRegions] = useState([])
    const [selectedSources, setSelectedSources] = useState([])
    const [allHierarchy, setAllHierarchy] = useState([])
    const [regionHierarchy, setRegionHierarchy] = useState([])
    const [dateSection, setDateSection] = useState(false)
    const [defaultData, setDefaultData] = useState([])
    const [selectedSubCategories, setSelectedSubCategories] = useState([])
    const [combinedRegCouPro, setCombinedRegCouPro] = useState([])
    const [allSources, setAllSources] = useState([])
    const [allSubCategories, setAllSubCategories] = useState([])
    const [allCompaniess, setAllCompaniess] = useState([])
    const [selectedOption, setSelectedOption] = useState('29')
    const [filtersCleared, setFiltersCleared] = useState(false)

    const token = getCookie('csrftoken')

    useEffect(() => {
        const headerTitle = document.getElementById('header_title')
        headerTitle.textContent = 'My Companies'
    }, [screenType])

    useEffect(() => {
        getRegions()
        getData('/news/all-companies/', setAllCompaniess)
        getData('/news/industries-sectors-topics/', setAllHierarchy)
        getData('/news/sources/', setAllSources)
        getData('/news/all-subcategories/', setAllSubCategories)
    }, [])

    const getData = (api, setState) => {
        axios.get(api).then(res => setState(res.data))
    }

    useEffect(() => {
        axios
            .get(`/endusers/user-${screenType}/`)
            .then(res => {
                setAllCompanies(res.data)
                if (res.data.length > 0) {
                    // setSelectedCompany(res.data[0].id)
                    setCompanyName({ id: res.data[0].id, name: res.data[0].name })
                }
            })
            .catch(err => console.log(err))
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    useEffect(() => {
        if (companyName.id) {
            setIsLoading(true)
            getCompanyInfoNews(companyName.id)
        }
    }, [companyName])

    useEffect(() => {
        if (filtersCleared) {
            getNews()
            setFiltersCleared(false)
        }
    }, [
        filtersCleared,
        selectedSources,
        selectedSubCategories,
        selectedCompanies,
        selectedTopics,
        selectedSectors,
        selectedRegions,
        selectedOption,
    ])

    const getRegions = () => {
        axios.get('/news/region-countries-provinces/').then(response => {
            const regions = response.data.map(item => ({
                ...item,
                id: item.type + '_' + item.id,
                countries: item.countries
                    ? item.countries.map(cty => ({
                          ...cty,
                          id: cty.type + '_' + cty.id,
                          provinces: cty.provinces
                              ? cty.provinces.map(pro => ({
                                    ...pro,
                                    id: pro.type + '_' + pro.id,
                                }))
                              : [],
                      }))
                    : [],
            }))
            const data = regions

            const customSort = (a, b) => {
                if (a.name === 'North America') {
                    return -1
                } else if (b.name === 'North America') {
                    return 1
                }
                return a.name.localeCompare(b.name)
            }

            const sortedData = data.sort((a, b) => customSort(a, b))

            sortedData.forEach(region => {
                region.countries.sort((a, b) => a.name.localeCompare(b.name))
            })

            setRegionHierarchy(sortedData)

            const result = sortedData.flatMap(regions => [
                { value: regions.id, label: regions.name, type: 'region' },
                ...regions.countries.flatMap(country => [
                    { value: country.id, label: country.name, type: 'country' },
                    ...country.provinces.map(province => ({
                        value: province.id,
                        label: province.name,
                        type: 'province',
                    })),
                ]),
            ])

            setCombinedRegCouPro(result)
        })
    }

    const getIndsecTop = () => {
        axios
            .get('/news/industries-sectors-topics/')
            .then(res => setAllHierarchy(res.data))
            .catch(err => console.log(err))
    }

    const QuickprogressSteps = ['Generating search strategy', 'Validating news results', 'Almost done']

    let timeoutIds = [] // Store timeout IDs to manage clearing

    const clearTimeouts = () => {
        timeoutIds.forEach(id => clearTimeout(id))
        timeoutIds = []
    }

    const progressContents = (responseTime = 7000, completionTime = 7000) => {
        const stepsArray = QuickprogressSteps

        clearTimeouts()

        stepsArray.forEach((step, index) => {
            const timeoutId = setTimeout(() => {
                const percentage = Math.round(((index + 1) / stepsArray.length) * 100)
                console.log(percentage)
                const displayPercentage = percentage > 94 ? 94 : percentage
                const steps = `${step}...(${displayPercentage}%)`
                setProgressContent(steps)
            }, index * (responseTime / stepsArray.length))
            timeoutIds.push(timeoutId) // Save the timeout ID
        })
    }

    const getCompanyInfoNews = id => {
        setCompanyInformation()
        // const completionTime = 15000
        // const responseTime = 15000
        setProgressContent('Working on it...(5%)')
        // const initialTimeoutId = setTimeout(() => {
        //     progressContents(responseTime, completionTime)
        //     setIsLoading(true)
        // }, 100)
        // timeoutIds.push(initialTimeoutId)
        if (payloadToken) {
            payloadToken.cancel('Operation canceled due to new request.')
        }
        const newCancelSource = axios.CancelToken.source()
        setPayloadToken(newCancelSource)
        axios
            .get(`/news/my-companies/?id=${id}`, { cancelToken: newCancelSource.token })
            .then(res => {
                setCompanyInformation(res.data.company_info)

                // setCompanyNews(res.data)

                getNews()
            })
            .catch(err => {
                // setIsLoading(false)
                if (axios.isCancel(err)) {
                    console.log('POST Request canceled:', err.message)
                    setProgressContent(null)
                    clearTimeouts()
                    setIsLoading(true)
                } else {
                    setProgressContent(null)
                    clearTimeouts()
                    setIsLoading(false)
                }
            })
        // .finally(() => {
        //     setIsLoading(false)
        // })
    }

    const getNews = date => {
        const completionTime = 25000
        const responseTime = 25000
        setProgressContent('Working on it...(5%)')
        const initialTimeoutId = setTimeout(() => {
            progressContents(responseTime, completionTime)
            setIsLoading(true)
        }, 100)
        timeoutIds.push(initialTimeoutId)
        if (payloadToken) {
            payloadToken.cancel('Operation canceled due to new request.')
        }
        const newCancelSource = axios.CancelToken.source()
        setPayloadToken(newCancelSource)

        const selectedDate = Number(date ? date : selectedOption)
        const cutoff =
            selectedDate <= 7 ? 3 : selectedDate === 29 ? 13 : selectedDate === 59 ? 23 : selectedDate === 89 ? 33 : 43
        var startDate = new Date()
        var endDate = new Date()
        startDate.setDate(startDate.getDate() - selectedDate)
        startDate = getYMD(startDate)
        endDate = getYMD(endDate)
        const allRegionName = regionHierarchy.map(val => val.name)

        const allCountryName = regionHierarchy
            .map(val => val.countries.map(cty => cty.name))
            .reduce((acc, curr) => acc.concat(curr), [])

        const allProvinceName = regionHierarchy
            .map(val => val.countries.map(cty => cty.provinces.map(pro => pro.name)))
            .reduce((acc, curr) => acc.concat(curr), [])
            .reduce((acc, curr) => acc.concat(curr), [])

        const payload = {
            query: companyName.name,
            relevance_cutoff: cutoff,
            prompt_type: 'Company Quick Search News Validation',
            semantic_preference: 0.5,
            type: 'level0',
            my_companies_page: true,

            filters: {
                articleDate: {
                    operator: 'BETWEEN',
                    from: startDate,
                    to: endDate,
                },
                company: { operator: 'IS', values: [companyName.name] },
                source: {
                    operator: 'IS',
                    values: selectedSources,
                },
                countries: {
                    operator: 'IS',
                    values: selectedRegions,
                },
                category: {
                    operator: 'IS',
                    values: selectedSubCategories,
                },
            },
        }

        payload.entities = {
            regions: allRegionName.filter(acc => selectedRegions.includes(acc)),
            provinces: allProvinceName.filter(acc => selectedRegions.includes(acc)),
            countries: allCountryName.filter(acc => selectedRegions.includes(acc)),
            companies: selectedCompanies,
            subcategories: selectedSubCategories,
        }

        console.log('payload', payload)
        axios
            .post('/semantic/quick-search/', payload, { cancelToken: newCancelSource.token })
            .then(res => {
                setCompanyNews(res.data)
                setProgressContent(null)
                clearTimeouts()
                setIsLoading(false)
            })
            .catch(err => {
                if (axios.isCancel(err)) {
                    console.log('POST Request canceled:', err.message)
                    setProgressContent(null)
                    clearTimeouts()
                    setIsLoading(true)
                } else {
                    setProgressContent(null)
                    clearTimeouts()
                    setIsLoading(false)
                }
            })
    }

    const companyMap = allCompanies.map(({ id, name }) => {
        return (
            <div
                key={id}
                onClick={() => setCompanyName({ id: id, name: name })}
                style={{
                    marginRight: '30px',
                    fontSize: '16px',
                    color: companyName.id === id ? 'rgb(25, 118, 210)' : 'black',
                    fontWeight: companyName.id === id ? 'bold' : 'normal',
                    cursor: 'pointer',
                }}
            >
                {name}
            </div>
        )
    })

    const handleLoadMore = () => {
        // const scrollUrl =
        //     companyNews.next !== null &&
        //     companyNews.next.replace('http://localhost:8000', '') &&
        //     companyNews.next.replace('http://kaitongo-backend:8000', '')
        // const defaultUnique = [...new Set(companyNews.data)]
        // companyNews.next !== null &&
        //     axios.get(scrollUrl).then(response => {
        //         let newsBefore = [...defaultUnique, ...response.data.data]
        //         let uniqueNews = [...new Set(newsBefore)]
        //         setCompanyNews({
        //             next: response.data.next,
        //             data: uniqueNews,
        //         })
        //     })
    }

    let interval

    const getPreMeetingData = () => {
        setPremeetingPreview(true)

        setTimeout(() => {
            setPreMeetingLoader(true)
        }, 100)
        setTimeout(() => {
            const search = document.getElementById('pregenerating')
            if (search) {
                const searchPlaceholder = 'Generating...'
                let i = 0
                let placeholder = ''
                typing()
                function typing() {
                    clearInterval(interval)
                    interval = setInterval(() => {
                        if (search) {
                            placeholder += searchPlaceholder[i]
                            i++
                            search.value = placeholder
                            search.scrollTop = search.scrollHeight
                            if (i > searchPlaceholder.length - 1) {
                                clearInterval(interval)
                            }
                        }
                    }, 50)
                }
            }
        }, 500)

        const pay = { company_id: companyName.id }

        axios
            .post(`/news/premium/`, pay)
            .then(res => {
                setPremeetingData(res.data)
            })
            .catch(err => console.log(err))
            .finally(() => {
                setPreMeetingLoader(false)
            })
    }

    const windowWidth = window.innerWidth
    const ConversationCheck =
        companyNews &&
        companyNews.conversation_starters &&
        companyNews.conversation_starters['Conversation Starters'] &&
        companyNews.conversation_starters['Conversation Starters'].length > 0
    const StrategicCheck =
        companyNews &&
        companyNews.strategic_priorities &&
        companyNews.strategic_priorities['Strategic Priorities'] &&
        companyNews.strategic_priorities['Strategic Priorities'].length > 0

    const clearFilters = () => {
        setSelectedSources([])
        setSelectedSubCategories([])
        setSelectedCompanies([])
        setSelectedTopics([])
        setSelectedSectors([])
        setSelectedRegions([])
        setSelectedOption('29')
        setFiltersCleared(true)

        // getNews()
    }

    return (
        <BaseScreen>
            <div style={{ padding: '0px 15px' }}>
                {newsFilters && (
                    <div ref={filterInputRef}>
                        <div
                            className="news_filter_section"
                            style={{
                                height: '100vh',
                                transition: 'opacity 3s ease',
                                overflow: 'auto',
                                position: 'fixed',
                                width: '240px',
                                top: 0,
                                left: 0,
                                zIndex: 10,
                                background: '#051340',
                                color: 'white',
                            }}
                        >
                            <div style={{}}>
                                <div
                                    className="d-flex justify-content-between align-items-center"
                                    style={{ width: '80%', margin: '21.5px auto' }}
                                >
                                    <label style={{ fontSize: '16px', color: 'white' }}>Filters</label>
                                    <span style={{ cursor: 'pointer' }} onClick={() => setNewsFilters(false)}>
                                        <IoIosClose style={{ fontSize: '26px' }} />
                                    </span>
                                </div>
                                <hr style={{ background: 'lightgray' }} />
                                <div style={{ margin: 'auto' }}>
                                    <QuickAdvanceFilters
                                        selectedSectors={selectedSectors}
                                        setSelectedSectors={setSelectedSectors}
                                        selectedCompanies={selectedCompanies}
                                        setSelectedCompanies={setSelectedCompanies}
                                        selectedCategories={selectedCategories}
                                        setSelectedCategories={setSelectedCategories}
                                        selectedTopics={selectedTopics}
                                        setSelectedTopics={setSelectedTopics}
                                        selectedRegions={selectedRegions}
                                        setSelectedRegions={setSelectedRegions}
                                        selectedSources={selectedSources}
                                        setSelectedSources={setSelectedSources}
                                        allHierarchy={allHierarchy}
                                        regionHierarchy={regionHierarchy}
                                        practiceSubCategory={[]}
                                        generateQuery={getNews}
                                        defaultData={defaultData}
                                        setDefaultData={setDefaultData}
                                        dateSection={dateSection}
                                        setDateSection={setDateSection}
                                        clearFilters={clearFilters}
                                        selectedSubCategories={selectedSubCategories}
                                        setSelectedSubCategories={setSelectedSubCategories}
                                        basicSearchInput={''}
                                        allCompanies={allCompaniess}
                                        allSources={allSources}
                                        allSubCategories={allSubCategories}
                                        selectedOption={selectedOption}
                                        setSelectedOption={setSelectedOption}
                                        isMyCompanyPage={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {!isLoading && allCompanies.length === 0 && (
                    <h1 style={{ textAlign: 'center' }}>
                        To see {screenType === 'companies' ? 'Company' : 'Competitor'} news, click My Preferences and
                        select {screenType}.
                    </h1>
                )}
                {allCompanies.length > 0 && (
                    <>
                        <div
                            className="indSelect__container"
                            style={{ height: '50px', borderTop: '1px solid lightgray' }}
                        >
                            <div className="indSelect__content" style={{ width: '100%' }}>
                                {companyMap.length > 5 ? (
                                    <ScrollMenu
                                        data={companyMap}
                                        arrowLeft={<ArrowBackIosNewSharp />}
                                        arrowRight={<ArrowForwardIosSharp />}
                                        dragging={true}
                                        wheel={false}
                                        hideSingleArrow={true}
                                        translate={5}
                                    />
                                ) : (
                                    <div className="d-flex" style={{ color: 'rgb(25, 118, 210)' }}>
                                        {companyMap}
                                    </div>
                                )}
                            </div>
                        </div>
                        {(!isLoading || (companyInformation && companyName.id && companyInformation.name)) && (
                            <div className="d-flex">
                                <div
                                    style={{
                                        width: !isLoading && (ConversationCheck || StrategicCheck) ? '65%' : '100%',
                                    }}
                                >
                                    <div
                                        style={{
                                            marginTop: '5%',
                                            width: '100%',
                                        }}
                                    >
                                        {companyName.id && companyInformation && (
                                            <div>
                                                <Description
                                                    Logo={companyInformation.logo}
                                                    Name={companyInformation.name}
                                                    Overview={companyInformation.description}
                                                    companyId={companyInformation.id}
                                                    companyUrl={companyInformation.url}
                                                    windowWidth={windowWidth}
                                                    getPreMeetingData={getPreMeetingData}
                                                    screenType={screenType}
                                                    Industry={companyInformation.sector_name}
                                                    Revenue={companyInformation.revenue}
                                                    Share={companyInformation.company_type}
                                                    Headquaters={companyInformation.headquater}
                                                />
                                                {/* <Overview
                                                Industry={companyInformation.vertical_sector_name}
                                                Revenue={companyInformation.revenue_mil}
                                                CompanyType={companyInformation.company_type}
                                                Management={companyInformation.key_employees}
                                                BoardMembers={companyInformation.board_members}
                                                windowWidth={windowWidth}
                                            /> */}
                                            </div>
                                        )}
                                    </div>
                                    {isLoading && (
                                        <div
                                            style={{ marginTop: allCompanies.length === 0 ? '' : '5%' }}
                                            className="d-flex align-items-center justify-content-center"
                                        >
                                            <CustomLoader progressContent={progressContent} />
                                        </div>
                                    )}

                                    {!isLoading && (
                                        <div>
                                            {companyNews && companyNews.data && companyNews.data.length > 0 ? (
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        marginLeft: '8px',
                                                    }}
                                                >
                                                    <CompanyNewsV2
                                                        CompanyNews={companyNews}
                                                        handleLoadMore={handleLoadMore}
                                                        setNewsFilters={setNewsFilters}
                                                        rowCheck={!isLoading && (ConversationCheck || StrategicCheck)}
                                                    />
                                                </div>
                                            ) : (
                                                <div style={{ marginTop: '2%' }}>
                                                    <h1 style={{ textAlign: 'center' }}>
                                                        No recent news for current preference. To change preference,
                                                        Click My Preferences and update {screenType}.
                                                    </h1>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {/* {companyNews && companyNews.data && companyNews.data.length > 0 && (
                                        <div className="companyNews__heading d-flex alig-items-center justify-content-between mb-2 p-2">
                                            <div
                                                className="headingGeneric company__overviewHeading"
                                                style={{ fontWeight: '800', fontSize: '20px' }}
                                            >
                                                News & Key Updates
                                            </div>
                                            <div
                                                style={{
                                                    cursor: 'pointer',
                                                    borderRadius: '4px',
                                                    background: 'white',
                                                    padding: '5px',
                                                    border: '1px solid lightgray',
                                                }}
                                                onClick={() => {
                                                    setNewsFilters(true)
                                                }}
                                            >
                                                <span>
                                                    <FilterListIcon />
                                                </span>
                                                <span className="ms-2">Filters</span>
                                            </div>
                                        </div>
                                    )} */}
                                </div>
                                {!isLoading && (ConversationCheck || StrategicCheck) && (
                                    <div
                                        style={{
                                            width: '35%',
                                            marginTop: '3%',
                                        }}
                                    >
                                        <div
                                        // style={{
                                        //     height:
                                        //         document.getElementsByClassName(
                                        //             'companyOverview__descriptionContainer'
                                        //         )[0].offsetHeight + 'px',
                                        //     overflow: 'auto',
                                        // }}
                                        >
                                            {ConversationCheck && (
                                                <div
                                                    style={{
                                                        // marginTop: '3%',
                                                        // width: '35%',
                                                        marginLeft: '3%',
                                                        background: 'white',
                                                        paddingBottom: '5px',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            fontSize: '24px',
                                                            background: '#9fb4c6',
                                                            fontWeight: 800,
                                                            padding: '8px',
                                                            textAlign: 'center',
                                                            width: '100%',
                                                        }}
                                                    >
                                                        Conversation Starters
                                                    </div>
                                                    {companyNews.conversation_starters['Conversation Starters']
                                                        .slice(0, 3)
                                                        .map((value, index) => (
                                                            <ConversationStarters content={value} index={index} />
                                                        ))}
                                                    {companyNews.conversation_starters['Conversation Starters'].length >
                                                        3 && (
                                                        <Button
                                                            variant="contained"
                                                            style={{
                                                                background: 'white',
                                                                borderRadius: '4px',
                                                                width: '90%',
                                                                display: 'flex',
                                                                fontWeight: 'bold',
                                                                fontSize: '14px',
                                                                height: '50px',
                                                                color: 'gray',
                                                                textTransform: 'none',
                                                                margin: '15px auto',
                                                                border: '1px solid gray',
                                                            }}
                                                            onClick={() => {
                                                                setOpenModal(true)
                                                            }}
                                                        >
                                                            View more
                                                        </Button>
                                                    )}
                                                </div>
                                            )}
                                            {StrategicCheck && (
                                                <div
                                                    style={{
                                                        marginTop: '3%',
                                                        // width: '35%',
                                                        marginLeft: '3%',
                                                        background: 'white',
                                                        paddingBottom: '5px',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            fontSize: '24px',
                                                            background: '#9fb4c6',
                                                            fontWeight: 800,
                                                            padding: '8px',
                                                            textAlign: 'center',
                                                            width: '100%',
                                                        }}
                                                    >
                                                        Strategic Priorities
                                                    </div>
                                                    {companyNews.strategic_priorities['Strategic Priorities']
                                                        .slice(0, 3)
                                                        .map((value, index) => (
                                                            <ConversationStarters content={value} index={index} />
                                                        ))}
                                                    {companyNews.strategic_priorities['Strategic Priorities'].length >
                                                        3 && (
                                                        <Button
                                                            variant="contained"
                                                            style={{
                                                                background: 'white',
                                                                borderRadius: '4px',
                                                                width: '90%',
                                                                display: 'flex',
                                                                fontWeight: 'bold',
                                                                fontSize: '14px',
                                                                height: '50px',
                                                                color: 'gray',
                                                                textTransform: 'none',
                                                                margin: '15px auto',
                                                                border: '1px solid gray',
                                                            }}
                                                            onClick={() => {
                                                                setStrategicModal(true)
                                                            }}
                                                        >
                                                            View more
                                                        </Button>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}
                {/* setPremeetingModal */}

                <ConversationModal
                    News={ConversationCheck ? companyNews.conversation_starters['Conversation Starters'] : []}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                />

                <ConversationModal
                    News={StrategicCheck ? companyNews.strategic_priorities['Strategic Priorities'] : []}
                    openModal={strategicModal}
                    setOpenModal={setStrategicModal}
                />

                <PreMeetingBriefModal
                    premeetingData={premeetingData}
                    setPremeetingPreview={setPremeetingPreview}
                    premeetingPreview={premeetingPreview}
                    preMeetingLoader={preMeetingLoader}
                    companyInformation={companyInformation}
                    token={token}
                />
            </div>
        </BaseScreen>
    )
}
