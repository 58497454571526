import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { getTrans } from '../../../utils'
import './AnalystSidebar.css'
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { IoMdSettings } from 'react-icons/io'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import ApartmentIcon from '@mui/icons-material/Apartment'

class EndUserAdminSidebar extends Component {
    state = {
        allowedPage: [
            'Find and Share',
            'Admin',
            'Template',
            'Insights Assistant',
            'Settings',
            'Dashboard',
            'My Request',
            'My Preferences',
            'Newsletters',
            'My Companies',
        ],
    }
    componentDidMount() {
        this.checkAllowedPage()
    }
    checkAllowedPage() {
        let currentPath = window.location.pathname
        if (currentPath === '/app/search/') {
            currentPath = 'Find and Share'
        } else if (currentPath.includes('admin')) {
            currentPath = 'Admin'
        } else if (currentPath.includes('template')) {
            currentPath = 'Template'
        } else if (currentPath.includes('dashboard')) {
            currentPath = 'Dashboard'
        } else if (currentPath === '/app/assistant/') {
            currentPath = 'Assistant'
        } else if (currentPath.includes('request')) {
            currentPath = 'My Request'
        } else if (currentPath.includes('preferences')) {
            currentPath = 'My Preferences'
        } else if (currentPath === '/app/newsletters/') {
            currentPath = 'Newsletters'
        } else if (currentPath.includes('companies')) {
            currentPath = 'My Companies'
        } else {
            window.location.pathname = '/app/search/'
        }
    }
    render() {
        const isPageAllowed = page => this.state.allowedPage.indexOf(page) !== -1

        return (
            <>
                {isPageAllowed('Insights Assistant') && (
                    <NavLink to="/app/assistant/" className="nav-item" id="assistant_navlink">
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <AutoAwesomeOutlinedIcon />
                            </span>
                            <span>{getTrans('Insights Assistant')}</span>
                        </div>
                    </NavLink>
                )}
                {/* {isPageAllowed('Dashboard') && (
                    <NavLink to="/app/dashboard/" className="nav-item">
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <DashboardOutlinedIcon />
                            </span>
                            <span>{getTrans('Dashboard')}</span>
                        </div>
                    </NavLink>
                )} */}
                {isPageAllowed('Find and Share') && (
                    <NavLink to="/app/search/" className="nav-item">
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <ManageSearchIcon />
                            </span>
                            <span>{getTrans('Find and Share')}</span>
                        </div>
                    </NavLink>
                )}

                {isPageAllowed('Newsletters') && (
                    <NavLink to="/app/newsletters/" className="nav-item">
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <ManageSearchIcon />
                            </span>
                            <span>{getTrans('Newsletters')}</span>
                        </div>
                    </NavLink>
                )}

                {isPageAllowed('My Request') && (
                    <NavLink to="/app/request/" className="nav-item">
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <DashboardOutlinedIcon />
                            </span>
                            <span>{getTrans('My Requests')}</span>
                        </div>
                    </NavLink>
                )}

                {isPageAllowed('My Companies') && (
                    <NavLink to="/app/companies/" className="nav-item">
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <ApartmentIcon />
                            </span>
                            <span>{getTrans('My Companies')}</span>
                        </div>
                    </NavLink>
                )}

                {isPageAllowed('My Preferences') && (
                    <NavLink to="/app/preferences/" className="nav-item">
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <AccountCircleIcon />
                            </span>
                            <span>{getTrans('My Preferences')}</span>
                        </div>
                    </NavLink>
                )}

                {isPageAllowed('Admin') && (
                    <NavLink
                        to="/app/admin/enduser/"
                        className={
                            window.location.pathname.includes('/app/admin/enduser/') ||
                            window.location.pathname.includes('app/admin/add-enduser')
                                ? 'nav-item active'
                                : 'nav-item'
                        }
                    >
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <SupervisorAccountIcon />
                            </span>
                            <span>{getTrans('Manage Users')}</span>
                        </div>
                    </NavLink>
                )}
                {isPageAllowed('Settings') && (
                    <NavLink to="/app/admin/firm/" className="nav-item">
                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '5px' }}>
                                <IoMdSettings />
                            </span>
                            <span>{getTrans('Firm Settings')}</span>
                        </div>
                    </NavLink>
                )}
            </>
        )
    }
}

export default EndUserAdminSidebar
