import React, { useEffect, useState } from 'react'
import BaseScreen from '../../BaseScreen/BaseScreen'
import axios from 'axios'
import ArrowBackIosNewSharp from '@mui/icons-material/ArrowBackIosNewSharp'
import ArrowForwardIosSharp from '@mui/icons-material/ArrowForwardIosSharp'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import { Circles } from 'react-loader-spinner'
import CompanyNewsV2 from '../../../components/CompanyScreen/CompanyNewsV2'
import Description from './Description'
import Overview from './Overview'
import html2pdf from 'html2pdf.js'
import { getCookie } from '../../../utils'
import PreMeetingBriefModal from '../../NewsFeedScreen/components/PreMeetingBriefModal'

export default function MyCompanyScreen(props) {
    const screenType = props.location.pathname === '/app/companies/' ? 'companies' : 'competitors'
    const [allCompanies, setAllCompanies] = useState([])
    const [selectedCompany, setSelectedCompany] = useState()
    const [companyInformation, setCompanyInformation] = useState()
    const [companyNews, setCompanyNews] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [premeetingPreview, setPremeetingPreview] = useState(false)
    const [premeetingData, setPremeetingData] = useState('')
    const [preMeetingLoader, setPreMeetingLoader] = useState(false)
    const token = getCookie('csrftoken')

    useEffect(() => {
        const headerTitle = document.getElementById('header_title')
        headerTitle.textContent = 'My Companies'
    }, [screenType])

    useEffect(() => {
        axios
            .get(`/endusers/user-${screenType}/`)
            .then(res => {
                setAllCompanies(res.data)
                if (res.data.length > 0) {
                    setSelectedCompany(res.data[0].id)
                }
            })
            .catch(err => console.log(err))
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    useEffect(() => {
        if (selectedCompany) {
            setIsLoading(true)
            getCompanyInfoNews(selectedCompany)
        }
    }, [selectedCompany])

    const getCompanyInfoNews = id => {
        axios
            .get(`/news/my-companies/?id=${id}`)
            .then(res => {
                setCompanyInformation(res.data.company_info)
                setCompanyNews(res.data)
            })
            .catch(err => console.log(err))
            .finally(() => {
                setIsLoading(false)
            })
    }

    const companyMap = allCompanies.map(({ id, name }) => {
        return (
            <div
                key={id}
                onClick={() => setSelectedCompany(id)}
                style={{
                    marginRight: '30px',
                    fontSize: '16px',
                    color: selectedCompany === id ? '#3b7dba' : 'black',
                    fontWeight: selectedCompany === id ? 'bold' : 'normal',
                    cursor: 'pointer',
                }}
            >
                {name}
            </div>
        )
    })

    const handleLoadMore = () => {
        const scrollUrl =
            companyNews.next !== null &&
            companyNews.next.replace('http://localhost:8000', '') &&
            companyNews.next.replace('http://kaitongo-backend:8000', '')
        const defaultUnique = [...new Set(companyNews.data)]
        companyNews.next !== null &&
            axios.get(scrollUrl).then(response => {
                let newsBefore = [...defaultUnique, ...response.data.data]
                let uniqueNews = [...new Set(newsBefore)]
                setCompanyNews({
                    next: response.data.next,
                    data: uniqueNews,
                })
            })
    }

    let interval

    const getPreMeetingData = () => {
        setPremeetingPreview(true)

        setTimeout(() => {
            setPreMeetingLoader(true)
        }, 100)
        setTimeout(() => {
            const search = document.getElementById('pregenerating')
            if (search) {
                const searchPlaceholder = 'Generating...'
                let i = 0
                let placeholder = ''
                typing()
                function typing() {
                    clearInterval(interval)
                    interval = setInterval(() => {
                        if (search) {
                            placeholder += searchPlaceholder[i]
                            i++
                            search.value = placeholder
                            search.scrollTop = search.scrollHeight
                            if (i > searchPlaceholder.length - 1) {
                                clearInterval(interval)
                            }
                        }
                    }, 50)
                }
            }
        }, 500)

        const pay = { company_id: selectedCompany }

        axios
            .post(`/news/premium/`, pay)
            .then(res => {
                setPremeetingData(res.data)
            })
            .catch(err => console.log(err))
            .finally(() => {
                setPreMeetingLoader(false)
            })
    }

    const windowWidth = window.innerWidth

    return (
        <BaseScreen>
            {isLoading && (
                <div style={{ marginTop: allCompanies.length === 0 ? '' : '5%', marginLeft: '20px' }}>
                    <Circles key={0} type="Grid" color="#adadad" height={80} width={80} />
                </div>
            )}
            {!isLoading && allCompanies.length === 0 && (
                <h1 style={{ textAlign: 'center' }}>
                    To see {screenType === 'companies' ? 'Company' : 'Competitor'} news, click My Preferences and select{' '}
                    {screenType}.
                </h1>
            )}
            {allCompanies.length > 0 && (
                <>
                    <div className="indSelect__container" style={{ height: '50px', borderTop: '1px solid lightgray' }}>
                        <div className="indSelect__content" style={{ width: '95%' }}>
                            {companyMap.length > 5 ? (
                                <ScrollMenu
                                    data={companyMap}
                                    arrowLeft={<ArrowBackIosNewSharp />}
                                    arrowRight={<ArrowForwardIosSharp />}
                                    dragging={true}
                                    wheel={false}
                                    hideSingleArrow={true}
                                    translate={5}
                                />
                            ) : (
                                <div className="d-flex">{companyMap}</div>
                            )}
                        </div>
                    </div>
                    {!isLoading && (
                        <>
                            <div>
                                <div style={{ marginTop: '5%' }}>
                                    {selectedCompany && companyInformation && (
                                        <div>
                                            <Description
                                                Logo={companyInformation.logo}
                                                Name={companyInformation.name}
                                                Overview={companyInformation.description}
                                                companyId={companyInformation.id}
                                                windowWidth={windowWidth}
                                                getPreMeetingData={getPreMeetingData}
                                                screenType={screenType}
                                            />
                                            <Overview
                                                Industry={companyInformation.vertical_sector_name}
                                                Revenue={companyInformation.revenue_mil}
                                                CompanyType={companyInformation.company_type}
                                                Management={companyInformation.key_employees}
                                                BoardMembers={companyInformation.board_members}
                                                windowWidth={windowWidth}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            {companyNews && companyNews.data && companyNews.data.length > 0 ? (
                                <div style={{ width: '98%', marginLeft: '8px' }}>
                                    <CompanyNewsV2 CompanyNews={companyNews} handleLoadMore={handleLoadMore} />
                                </div>
                            ) : (
                                <div style={{ marginTop: '2%' }}>
                                    <h1 style={{ textAlign: 'center' }}>
                                        No recent news for current preference. To change preference, Click My
                                        Preferences and update {screenType}.
                                    </h1>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
            {/* setPremeetingModal */}

            <PreMeetingBriefModal
                premeetingData={premeetingData}
                setPremeetingPreview={setPremeetingPreview}
                premeetingPreview={premeetingPreview}
                preMeetingLoader={preMeetingLoader}
                companyInformation={companyInformation}
                token={token}
            />
        </BaseScreen>
    )
}
